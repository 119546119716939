import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { FLIGHT_STATES } from "../../config/flightStatusColor";

function operationStateText(operationState) {
  let color;
  if (operationState === FLIGHT_STATES.NON_CONFORMANT) {
    color = "red";
  } else {
    color = "black";
  }
  return <span style={{ fontWeight: "bold", color }}> {operationState} </span>;
}

function FormatBoldDisplay(info) {
  return <span style={{ fontWeight: "bold" }}> {info} </span>;
}

function AlertReasonText({ newOperatingState, operationID, platformCallSign }) {
  switch (newOperatingState) {
    case "Conforming":
      return (
        <Typography gutterBottom>
          Operation with platform {FormatBoldDisplay(platformCallSign)} has
          returned to its conformance volume and has therefore entered{" "}
          {operationStateText(newOperatingState)} state.
        </Typography>
      );
    case FLIGHT_STATES.NON_CONFORMANT:
      return (
        <Typography gutterBottom>
          Operation with platform {FormatBoldDisplay(platformCallSign)} has
          deviated outside its conformance volume and has therefore entered{" "}
          {operationStateText(newOperatingState)} state.
        </Typography>
      );
    case "Contingency":
      return (
        <Typography gutterBottom>
          Operation with platform {FormatBoldDisplay(platformCallSign)} has
          deviated outside its conformance volume beyond the allowed tolerance
          and has therefore entered {operationStateText(newOperatingState)}{" "}
          state.
        </Typography>
      );
    default:
      return (
        <Typography gutterBottom>
          Operation with platform {FormatBoldDisplay(platformCallSign)} has
          entered
          {operationStateText(newOperatingState)} state.
        </Typography>
      );
  }
}

export function WebsocketAlertComponent({
  open,
  handleClose,
  websocketMessage,
}) {
  if (!websocketMessage) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Conformance Alert</DialogTitle>
      <DialogContent>
        <AlertReasonText
          newOperatingState={websocketMessage.new_state}
          operationID={websocketMessage.operationID}
          platformCallSign={websocketMessage.platformCallSign}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
}
