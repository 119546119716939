import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { createInstance } from "../api";

export function* getShipData() {
  try {
    const jwtToken = yield select(
      (state) => state.auth.info.accessToken.jwtToken
    );

    const o = createInstance(jwtToken);
    const apiResponse = yield call(o.getShips, null);
    yield put({
      type: "SET_SHIP_DATA",
      payload: apiResponse.data,
    });
  } catch (error) {
    yield put({
      type: "GET_SHIP_DATA_ERROR",
      error,
    });
  }
}

/*
    Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
    Allows concurrent fetches of user.
  */
export function* fetchShipData() {
  yield takeEvery("GET_SHIP_DATA", getShipData);
}

export default function* root() {
  yield all([fetchShipData()]);
}
