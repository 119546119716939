import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { ButtonGroup , Tabs, Tab, Typography } from "@material-ui/core";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import DeleteIcon from "@material-ui/icons/Delete";
import CenterFocusIcon from "@material-ui/icons/CenterFocusStrong";
import { format } from "date-fns";
import { useApi } from "../../../api/useApi";

import { setSnackbarMessage } from "../../../actions";

function Panel(props) {
  return <div hidden={props.value !== props.index}>
    {props.value === props.index && <Typography>{props.children}</Typography>}
  </div>
}

const TypeOptions = [
  {
    value: 0,
    label: "vlos",
  },
  {
    value: 1,
    label: "bvlos",
  },
];

function AreaEntries(props) {
  const { selectedAreaWaypoints, onChange, onFocus } = props;
  const numbersOfWaypoints = selectedAreaWaypoints?.length;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>WP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      {selectedAreaWaypoints.map((waypoint, i) => (
        <>
          <span style={{ height: "12px", lineHeight: "12px", margin: "auto" }}>
            {i + 1}
          </span>
          <input
            type="number"
            width={100}
            value={waypoint[1]}
            name={`waypointLatititude${  i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][1] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          />
          <input
            width={100}
            type="number"
            value={waypoint[0]}
            name={`waypointLongitude${  i}`}
            onChange={(ev) => {
              selectedAreaWaypoints[i][0] = Number.parseFloat(ev.target.value);
              onChange([...selectedAreaWaypoints]);
            }}
          />
          <ButtonGroup>
            <Button onClick={() => onFocus(waypoint)}>
              <CenterFocusIcon />
            </Button>
            <Button
              onClick={() => {
                onChange([
                  ...selectedAreaWaypoints.slice(0, i),
                  ...selectedAreaWaypoints.slice(i + 1),
                ]);
              }}
              // disabled={i === 0 || i + 1 === numbersOfWaypoints}
              disabled={i <= 2}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </>
      ))}

      <Button
        variant="outlined"
        fullWidth
        style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
        onClick={() => {
          onChange([
            ...selectedAreaWaypoints.slice(0, -1),
            [0, 0],
            selectedAreaWaypoints[numbersOfWaypoints - 1],
          ]);
        }}
      >
        Add way point
      </Button>
    </div>
  );
}

function WaypointsEntries(props) {
  const { selectedWaypoints, onChange, onFocus } = props;
  const numbersOfWaypoints = selectedWaypoints?.length;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 5fr 5fr 1fr",
        gap: "0.25rem",
      }}
    >
      <h4 style={{ textAlign: "center" }}>WP</h4>
      <h4 style={{ textAlign: "center" }}>LAT (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>LONG (DECIMAL)</h4>
      <h4 style={{ textAlign: "center" }}>Actions</h4>
      {selectedWaypoints.map((waypoint, i) => (
        <>
          {i === 0 && <FlightTakeoffIcon style={{ margin: "auto" }} />}
          {i + 1 === numbersOfWaypoints && (
            <FlightLandIcon style={{ margin: "auto" }} />
          )}
          {i > 0 && i + 1 < numbersOfWaypoints && (
            <span
              style={{ height: "12px", lineHeight: "12px", margin: "auto" }}
            >
              {i}
            </span>
          )}
          <input
            type="number"
            width={100}
            value={waypoint[1]}
            name={`waypointLatititude${  i}`}
            onChange={(ev) => {
              selectedWaypoints[i][1] = Number.parseFloat(ev.target.value);
              onChange([...selectedWaypoints]);
            }}
          />
          <input
            width={100}
            type="number"
            value={waypoint[0]}
            name={`waypointLongitude${  i}`}
            onChange={(ev) => {
              selectedWaypoints[i][0] = Number.parseFloat(ev.target.value);
              onChange([...selectedWaypoints]);
            }}
          />
          <ButtonGroup>
            <Button onClick={() => onFocus(waypoint)}>
              <CenterFocusIcon />
            </Button>
            <Button
              onClick={() => {
                onChange([
                  ...selectedWaypoints.slice(0, i),
                  ...selectedWaypoints.slice(i + 1),
                ]);
              }}
              disabled={i === 0 || i + 1 === numbersOfWaypoints}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        </>
      ))}

      <Button
        variant="outlined"
        fullWidth
        style={{ gridColumnStart: 1, gridColumnEnd: 5 }}
        onClick={() => {
          onChange([
            ...selectedWaypoints.slice(0, -1),
            [0, 0],
            selectedWaypoints[numbersOfWaypoints - 1],
          ]);
        }}
      >
        Add way point
      </Button>
    </div>
  );
}

function CreateOperationFormComponent(props) {
  const {
    selectedWaypoints: _selectedWaypoints,
    selectedAreaWaypoints: _selectedAreaWaypoints,
    onUpdateSelectedWaypoints,
    onUpdateSelectedAreaWaypoints,
    onEmergencyLandingUpdate,
    mapViewController,
    emergencyLanding,
  } = props;
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [selectedWaypoints, setSelectedPolyline] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [selectedAreaWaypoints, setSelectedAreaPolgon] = useState([
    [0.0, 0.0],
    [0.0, 0.0],
    [0.0, 0.0],
  ]);
  const [platformid, setPlatformID] = useState();
  const [pilotid, setPilotID] = useState();
  const [operationType, setOperationType] = useState("vlos");
  const [availablePilots, setAvailablePilots] = useState([]);
  const currentTime = new Date();
  const [departureTime, setDepartureTime] = useState(new Date(currentTime.getTime() + 3*60000)); // Default set as 3 mins from current time
  const [departureTimeError, setDepartureTimeError] = useState("");
  const [endTime, setEndTime] = useState(new Date(departureTime.getTime() + 30*60000)); // Default set as 30 mins from start time
  const [endTimeError, setEndTimeError] = useState("");
  const [contingencyLandingPoint, setContingencyLandingPoint] = useState([]);
  const [safeAltitude, setSafeAltitude] = useState(60);
  const [operationDescription, setOperationDescription] = useState();
  const [speed, setSpeed] = useState(5);
  const [altitude, setAltitude] = useState(60);
  const [loading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const onTabClicked = (event, index) => {
    setTabIndex(index);
    if (index === 0) {
      onSelectWaypointMethod("area");
    } else if (index === 1) {
      onSelectWaypointMethod("route");
    } else if (index === 2) {
      onSelectWaypointMethod("upload");
    }
  };
  const dispatch = useDispatch();
  const api = useApi();
  const [selectWaypointMethod, setSelectWaypointMethod] = useState("area");
  const handleSubmitOperation = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleSubmitOperation(true);

    // Submit Operation
    if (selectWaypointMethod == "area") {
      // "area operation"
      try {
        const response = await api.submitAreaOperation({
          pilot_uuid: pilotid,
          platform_uuid: platformid,
          area_coordinates: selectedAreaWaypoints,
          altitude,
          altitude_reference: "W84",
          time_start: departureTime.toISOString(),
          time_end: endTime.toISOString(),
          operation_type: operationType,
          description: operationDescription,
          contingency_plans: {
            landing_point: [contingencyLandingPoint],
            safe_altitude: safeAltitude,
          },
        });

        if (response.data) {
          handleSubmitOperation(response.data);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "Operation submitted successfully",
              error: "success",
            })
          );
        }
      } catch (err) {
        handleSubmitOperation(false);

        dispatch(
          setSnackbarMessage({
            open: true,
            message: err.response.data.message,
            severity: "error",
          })
        );
      }
    } else {
      // "route operation"
      try {
        const response = await api.submitOperation({
          pilot_uuid: pilotid,
          platform_uuid: platformid,
          waypoints: selectedWaypoints,
          altitude,
          altitude_reference: "W84",
          time_start: departureTime.toISOString(),
          ground_speed: speed,
          operation_type: operationType,
          description: operationDescription,
          contingency_plans: {
            landing_point: [contingencyLandingPoint],
            safe_altitude: safeAltitude,
          },
        });
        if (response.data) {
          handleSubmitOperation(response.data);
          dispatch(
            setSnackbarMessage({
              open: true,
              message: "Operation submitted successfully",
              error: "success",
            })
          );
        }
      } catch (err) {
        handleSubmitOperation(false);

        dispatch(
          setSnackbarMessage({
            open: true,
            message: err.response.data.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleOnFocus = async (center) => {
    mapViewController.center = center;
  };
  const getPlatforms = async () => {
    try {
      const response = await api.getPlatforms();
      if (response.data) {
        setAvailablePlatforms(response.data);
        setLoading(false);
        setPlatformID(response.data[0].platform_uuid);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getPilots = async () => {
    try {
      const response = await api.getPilots();
      if (response.data) {
        setAvailablePilots(response.data);
        setLoading(false);
        setPilotID(response.data[0].pilot_uuid);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlatforms();
    getPilots();
  }, []);

  useEffect(() => {
    if (_selectedWaypoints.length) {
      setSelectedPolyline(_selectedWaypoints);

      if (!contingencyLandingPoint[0] || !contingencyLandingPoint[1]) {
        setContingencyLandingPoint([
          _selectedWaypoints[0][0],
          _selectedWaypoints[0][1]
        ])
      }
    }
  }, [_selectedWaypoints]);

  useEffect(() => {
    if (_selectedAreaWaypoints.length) {
      setSelectedAreaPolgon(_selectedAreaWaypoints);

      if (!contingencyLandingPoint[0] || !contingencyLandingPoint[1]) {
        setContingencyLandingPoint([
          _selectedAreaWaypoints[0][0],
          _selectedAreaWaypoints[0][1]
        ])
      }
    }
  }, [_selectedAreaWaypoints]);

  useEffect(() => {
    if (emergencyLanding.length) {
      setContingencyLandingPoint([...emergencyLanding]);
    }
  }, [emergencyLanding]);

  const handleDepartureTimeChange = (item) => {
    setDepartureTime(new Date(item));
    setDepartureTimeError("");
  };

  const handleDefaultEndTime = (dt) => {
    dt.setMinutes( dt.getMinutes() + 30 );
    return [
      format(dt, "yyyy-MM-dd"),
      "T",
      format(dt, "HH:mm"),
    ].join("")
  };

  const handleEndTimeChange = (item) => {
    setEndTime(new Date(item));
    setEndTimeError("");
  };

  const onSelectWaypointMethod = (label) => {
    setSelectWaypointMethod(label);
  };

  if (loading) return <div />;

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <div>
        <h3>Select setup methods:</h3>
        <Tabs variant="scrollable" value={tabIndex} onChange={onTabClicked}>
          <Tab label="Area" />
          <Tab label="Route" />
          <Tab label="Upload Mission" />
        </Tabs>

        {/* Area */}
        <Panel value={tabIndex} index={0}>
          <AreaEntries
            onFocus={handleOnFocus}
            selectedAreaWaypoints={selectedAreaWaypoints}
            onChange={(updatedWaypoints) => {
              setSelectedAreaPolgon(updatedWaypoints);
              onUpdateSelectedAreaWaypoints(updatedWaypoints);
            }}
          />
          <Grid container spacing={3}>
            <Grid item xs={8} style={{ marginTop: "15px" }}>
              <TextField
                id="platform"
                label="Platform Callsign"
                fullWidth
                select
                value={platformid || ""}
                onChange={(item) => {
                  setPlatformID(item.target.value);
                }}
              >
                {availablePlatforms.map((option) => (
                  <MenuItem
                    key={option.platform_uuid}
                    value={option.platform_uuid}
                  >
                    {option.platform_callsign}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="pilot"
                label="Pilot Name"
                fullWidth
                select
                value={pilotid || ""}
                onChange={(item) => {
                  setPilotID(item.target.value);
                }}
              >
                {availablePilots.map((option) => (
                  <MenuItem key={option.pilot_uuid} value={option.pilot_uuid}>
                    {option.pilot_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="type"
                label="Type"
                fullWidth
                select
                value={operationType || ""}
                onChange={(item) => setOperationType(item.target.value)}
              >
                {TypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="ContingencyLat"
                label="Emergency Landing Lat (deg)"
                fullWidth
                value={contingencyLandingPoint[1] || 0}
                onChange={(item) => {
                  const updated = [
                    contingencyLandingPoint[0],
                    Number.parseFloat(item.target.value),
                  ];
                  setContingencyLandingPoint(updated);
                  onEmergencyLandingUpdate(updated);
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="ContingencyLon"
                label="Emergency Landing Lon (deg)"
                fullWidth
                value={contingencyLandingPoint[0] || 0}
                onChange={(item) => {
                  const updated = [
                    Number.parseFloat(item.target.value),
                    contingencyLandingPoint[1],
                  ];
                  setContingencyLandingPoint(updated);
                  onEmergencyLandingUpdate(updated);
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="speed"
                label="Speed (m/s)"
                fullWidth
                value={speed || 5}
                onChange={(item) => setSpeed(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="altitude"
                label="Max Altitude (m)"
                fullWidth
                value={altitude || 60}
                onChange={(item) => setAltitude(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="safeAltitude"
                label="RTH altitude (m)"
                fullWidth
                value={safeAltitude || 60}
                onChange={(item) => setSafeAltitude(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="operationDescription"
                label="Operation Intent"
                fullWidth
                value={operationDescription}
                onChange={(item) => setOperationDescription(item.target.value)}
                type="string"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="start-time"
                label="Start Time (Local Time)"
                type="datetime-local"
                defaultValue={[
                  format(departureTime, "yyyy-MM-dd"),
                  "T",
                  format(departureTime, "HH:mm"),
                ].join("")}
                fullWidth
                onChange={(item) =>
                  handleDepartureTimeChange(item.target.value)
                }
                error={!!departureTimeError}
                helperText={departureTimeError}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="end-time"
                label="End Time (Local Time)"
                type="datetime-local"
                // defaultValue={handleDefaultEndTime(new Date())}
                defaultValue={[
                  format(endTime, "yyyy-MM-dd"),
                  "T",
                  format(endTime, "HH:mm"),
                ].join("")}
                fullWidth
                onChange={(item) =>
                  handleEndTimeChange(item.target.value)
                }
                error={!!endTimeError}
                helperText={endTimeError}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "25px" }}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                selectedAreaWaypoints.filter(([a, b]) => !a || !b).length
              }
            >
              Submit Operation
            </Button>
          </div>
        </Panel>

        {/* Route */}
        <Panel value={tabIndex} index={1}>
          <WaypointsEntries
            onFocus={handleOnFocus}
            selectedWaypoints={selectedWaypoints}
            onChange={(updatedAreaWaypoints) => {
              setSelectedPolyline(updatedAreaWaypoints);
              onUpdateSelectedWaypoints(updatedAreaWaypoints);
            }}
          />
          <Grid container spacing={3}>
            <Grid item xs={8} style={{ marginTop: "15px" }}>
              <TextField
                id="platform"
                label="Platform Callsign"
                fullWidth
                select
                value={platformid || ""}
                onChange={(item) => {
                  setPlatformID(item.target.value);
                }}
              >
                {availablePlatforms.map((option) => (
                  <MenuItem
                    key={option.platform_uuid}
                    value={option.platform_uuid}
                  >
                    {option.platform_callsign}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="pilot"
                label="Pilot Name"
                fullWidth
                select
                value={pilotid || ""}
                onChange={(item) => {
                  setPilotID(item.target.value);
                }}
              >
                {availablePilots.map((option) => (
                  <MenuItem key={option.pilot_uuid} value={option.pilot_uuid}>
                    {option.pilot_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="type"
                label="Type"
                fullWidth
                select
                value={operationType || ""}
                onChange={(item) => setOperationType(item.target.value)}
              >
                {TypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="ContingencyLat"
                label="Emergency Landing Lat (deg)"
                fullWidth
                value={contingencyLandingPoint[1] || selectedWaypoints[0][1]}
                onChange={(item) => {
                  const updated = [
                    contingencyLandingPoint[0],
                    Number.parseFloat(item.target.value),
                  ];
                  setContingencyLandingPoint(updated);
                  onEmergencyLandingUpdate(updated);
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="ContingencyLon"
                label="Emergency Landing Lon (deg)"
                fullWidth
                value={contingencyLandingPoint[0] || selectedWaypoints[0][0]}
                onChange={(item) => {
                  const updated = [
                    Number.parseFloat(item.target.value),
                    contingencyLandingPoint[1],
                  ];
                  setContingencyLandingPoint(updated);
                  onEmergencyLandingUpdate(updated);
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="speed"
                label="Speed (m/s)"
                fullWidth
                value={speed || 5}
                onChange={(item) => setSpeed(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="altitude"
                label="Cruise Altitude (m)"
                fullWidth
                value={altitude || 60}
                onChange={(item) => setAltitude(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="safeAltitude"
                label="RTH altitude (m)"
                fullWidth
                value={safeAltitude || 60}
                onChange={(item) => setSafeAltitude(item.target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="operationDescription"
                label="Operation Intent"
                fullWidth
                value={operationDescription}
                onChange={(item) => setOperationDescription(item.target.value)}
                type="string"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="departure-time"
                label="Departure Time (Local Time)"
                type="datetime-local"
                defaultValue={[
                  format(departureTime, "yyyy-MM-dd"),
                  "T",
                  format(departureTime, "HH:mm"),
                ].join("")}
                fullWidth
                onChange={(item) =>
                  handleDepartureTimeChange(item.target.value)
                }
                error={!!departureTimeError}
                helperText={departureTimeError}
              />
            </Grid>
          </Grid>
          <div style={{ marginTop: "25px" }}>
            <Button
              type="submit"
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                selectedWaypoints.filter(([a, b]) => !a || !b).length
              }
            >
              Submit Operation
            </Button>
          </div>
        </Panel>

        {/* Upload */}
        <Panel value={tabIndex} index={2}>
          This feature is under development.
        </Panel>
      </div>
    </form>
  );
}

export default CreateOperationFormComponent;
