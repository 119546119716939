import { SET_TRACKING_MAP_VIEW } from "./maps";

export const SET_AUTH = "SET_AUTH";
export const setSubscriptionStatus = (status) => ({
  type: "SET_SUBSCRIPTION_STATUS",
  status,
});

export const setSnackbarMessage = ({ open = true, message, severity }) => ({
  type: "SET_SNACKBAR_MESSAGE",
  open,
  message,
  severity,
});

export const setShipData = (data) => ({
  type: "SET_SHIP_DATA",
  data,
});

export const getShipData = (data) => ({
  type: "GET_SHIP_DATA",
  data,
});

export const setConstraintsData = (data) => ({
  type: "SET_CONSTRAINTS_DATA",
  data,
});

export const setADSBdata = (data) => ({
  type: "SET_ADSB_DATA",
  data,
});

export const setTrackingMapView = (trackingMapView) => ({
  type: SET_TRACKING_MAP_VIEW,
  data: {
    trackingMapView,
  },
});

export const SubscriptionStatus = {
  NONE: "None",
  SELECTING: "Selecting",
  CREATING: "Creating",
  ACTIVE: "Active",
  DELETING: "Deleting",
};

export const setAuth = (auth) => ({
  type: SET_AUTH,
  data: auth,
});
