const initialState = { open: false, message: "", severity: "" };
const snackbarMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SNACKBAR_MESSAGE": {
      return {
        open: action.open,
        message: action.message,
        severity: action.severity,
      };
    }
    default:
      return state;
  }
};

export default snackbarMessageReducer;
