// import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import PuckServer from "../../../services/puckserver";
import generateKML from "../../../services/kml";
import { useApi } from "../../../api/useApi";
import { setSnackbarMessage } from "../../../actions";
import {
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
} from "../../../config/flightStatusColor";

const puckServer = PuckServer;

function OperationControlList({
  operationsData,
  telemetryData,
  selectedPlatformData,
  onRemoveClick,

  onVisabilityClick,
  setUpdatingOperation,
  onUpdateOperationState,
}) {
  const trackingMapView = useSelector((state) => state.maps.trackingMapView);
  const [focusedFlight, setFocusedFlight] = useState();
  const handleOnFocus = (operationOnFocus) => {
    const highlightVolume = (layer, id) => {
      for (const graphic of layer.graphics) {
        const volumeSymbol = graphic.symbol.clone();
        const symbolLayer = volumeSymbol.symbolLayers.getItemAt(0);
        let { color } = symbolLayer.material;
        const operation = operationsData.find(
          (op) => op.reference.id === graphic.attributes.id
        );
        switch (operation.details.state) {
          case FLIGHT_STATES.CONTINGENT: {
            color = HIGHLIGHT_COLORS.red;
            break;
          }
          case FLIGHT_STATES.NON_CONFORMANT: {
            color = HIGHLIGHT_COLORS.orange;
            break;
          }
          case FLIGHT_STATES.ACTIVATED: {
            color = HIGHLIGHT_COLORS.green;
            break;
          }
          default:
            color = HIGHLIGHT_COLORS.gray;
            break;
        }
        color =
          graphic.attributes.id === operationOnFocus.reference.id
            ? HIGHLIGHT_COLORS.blue
            : color;
        symbolLayer.material.color = color;
        volumeSymbol.symbolLayers = [symbolLayer];
        // UPDATE SYMBOL //
        graphic.symbol = volumeSymbol;
      }
    };
    highlightVolume(
      trackingMapView.allLayerViews.find(
        (v) => v.layer.title === "FlightOperationVolume"
      ).layer,
      operationOnFocus.reference.id
    );
    if (operationOnFocus.request.area_coordinates) {
      const [center] = operationOnFocus.request.area_coordinates;
      trackingMapView.center = center;
    } else {
      trackingMapView.center = operationOnFocus.request.arrival;
    }

    setFocusedFlight(operationOnFocus.reference.id);
  };

  return operationsData.map((operation, index) => (
    <OperationControl
      operation={operation}
      telemetryData={telemetryData[index]}
      highlighted={operation.reference.id === focusedFlight}
      onFocus={handleOnFocus}
      onRemoveClick={onRemoveClick}
      platformData={selectedPlatformData[index]}
      onUpdateOperationState={onUpdateOperationState}
      onVisabilityClick={onVisabilityClick}
      setUpdatingOperation={setUpdatingOperation}
    />
  ));
}

function OperationDetails({ operation, platformData }) {
  return (
    <div style={{ textAlign: "left" }}>
      <div>
        <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
          {operation.reference.description}
        </Typography>
        <PlatformCallSign platformData={platformData} />
        <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
          Depart -
          {format(
            new Date(operation.interuss.operation_reference.time_start.value),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Typography>
        <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
          Arrive -
          {format(
            new Date(operation.interuss.operation_reference.time_end.value),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Typography>
      </div>
    </div>
  );
}

function PlatformCallSign({ platformData }) {
  if (platformData != null) {
    return (
      <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
        {platformData.data.platform_callsign}
      </Typography>
    );
  }
  return (
    <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
      Undefined
    </Typography>
  );
}

function TelemetryDetails({ telemetryData, platformData }) {
  const dispatch = useDispatch();
  const [trackerEnabled, setTrackerEnabled] = useState(false);

  if (telemetryData != null) {
    if (
      !trackerEnabled &&
      telemetryData?.puckResponse?.operational_status !== "Undeclared"
    ) {
      setTrackerEnabled(true);
    }
    if (
      trackerEnabled &&
      telemetryData?.puckResponse?.operational_status === "Undeclared"
    ) {
      setTrackerEnabled(false);
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Tracker ${platformData.data.platform_callsign} has been switched off.`,
          severity: "error",
        })
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography
          style={{ fontWeight: "bold", color: "primary", fontSize: "12px" }}
        >
          Speed - {trackerEnabled ? telemetryData?.puckResponse?.speed : " "}{" "}
          m/s
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
            color: "primary",
            fontSize: "12px",
            marginLeft: "20px",
          }}
        >
          Alt -{" "}
          {trackerEnabled ? telemetryData.puckResponse?.position?.alt : " "} m
          AMSL
        </Typography>
        <Typography
          style={{
            fontWeight: "bold",
            color: "primary",
            fontSize: "12px",
            marginLeft: "20px",
          }}
        >
          Hdg - {trackerEnabled ? telemetryData?.puckResponse?.track : " "} deg
        </Typography>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Typography
        style={{ fontWeight: "bold", color: "primary", fontSize: "12px" }}
      >
        Speed - Undefined
      </Typography>
      <Typography
        style={{
          fontWeight: "bold",
          color: "primary",
          fontSize: "12px",
          marginLeft: "20px",
        }}
      >
        Alt - Undefined
      </Typography>
    </div>
  );
}

function VisabilityIcon({ iconVisability, handleVisibilityClick }) {
  if (iconVisability) {
    return (
      <VisibilityIcon
        onClick={handleVisibilityClick}
        style={{ fontSize: "24px", cursor: "pointer" }}
      />
    );
  }
  return (
    <VisibilityOffIcon
      onClick={handleVisibilityClick}
      style={{ fontSize: "24px", cursor: "pointer" }}
    />
  );
}

function OperationControl({
  operation,
  telemetryData,
  platformData,
  onRemoveClick,
  onUpdateOperationState,
  onVisabilityClick,
  setUpdatingOperation,
  highlighted,
  onFocus,
}) {
  const [iconVisability, setIconVisability] = useState(true);

  const dispatch = useDispatch();

  const handleVisibilityClick = (e) => {
    e.preventDefault();
    if (iconVisability) {
      onVisabilityClick(false, operation.reference.id);
      setIconVisability(false);
    } else {
      setIconVisability(true);
      onVisabilityClick(true, operation.reference.id);
    }
  };
  const api = useApi();
  const handleRemoveClick = async (e) => {
    e.preventDefault();

    await api.removeOperationSelection(operation.reference.id);
    onRemoveClick(operation.reference.id);
    dispatch(
      setSnackbarMessage({
        message: "Operation removed",
        severity: "success",
      })
    );
  };

  let homeVisable;
  let landVisable;
  let pauseVisable;
  let startVisable;
  let contingentVisable;
  let uploadVisable;
  switch (operation.details.state) {
    case "Accepted":
      homeVisable = true;
      landVisable = true;
      pauseVisable = true;
      startVisable = true;
      contingentVisable = false;
      uploadVisable = true;
      break;
    case "Activated":
      homeVisable = true;
      landVisable = true;
      pauseVisable = true;
      startVisable = false;
      contingentVisable = true;
      uploadVisable = false;
      break;
    case FLIGHT_STATES.NON_CONFORMANT:
      homeVisable = true;
      landVisable = true;
      pauseVisable = true;
      startVisable = false;
      contingentVisable = false;
      uploadVisable = false;
      break;
    case "Contingency":
      homeVisable = true;
      landVisable = true;
      pauseVisable = true;
      startVisable = false;
      contingentVisable = false;
      uploadVisable = false;
      break;
    case "Ended":
      homeVisable = false;
      landVisable = false;
      pauseVisable = false;
      startVisable = false;
      contingentVisable = false;
      break;
    default:
      homeVisable = false;
      landVisable = false;
      pauseVisable = false;
      startVisable = false;
      contingentVisable = false;
  }

  const handleStartClick = async (e) => {
    e.preventDefault();
    setUpdatingOperation(true);

    //  Send notification to MARS
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Activated";
    try {
      const res = await api.updateOperation(
        operation.reference.id,
        operation.operation_state
      );

      onUpdateOperationState(res.data);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
      setUpdatingOperation(false);
      return;
    }

    setUpdatingOperation(false);
  };

  const handleLandClick = async (e) => {
    e.preventDefault();
    setUpdatingOperation(true);

    // Send notification to MARS
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Ended";
    try {
      const res = await api.updateOperation(
        operation.reference.id,
        operation.operation_state
      );

      onUpdateOperationState(res.data);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
      setUpdatingOperation(false);
      return;
    }

    setUpdatingOperation(false);
  };

  const handleContingentClick = async (e) => {
    e.preventDefault();
    setUpdatingOperation(true);

    // Send notification to MARS
    // eslint-disable-next-line no-param-reassign
    operation.operation_state = "Contingent";
    try {
      const res = await api.updateOperation(
        operation.reference.id,
        operation.operation_state
      );

      onUpdateOperationState(res.data);
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: `Unable to notify MARS of operation state change - ${err}`,
          severity: "error",
        })
      );
      setUpdatingOperation(false);
      return;
    }

    setUpdatingOperation(false);
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault();
    const element = document.createElement("a");
    const file = new Blob([generateKML(operation.details.waypoints)], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${operation.reference.id}.kml`;
    document.body.appendChild(element);
    element.click();
  };
  const backgroundColor = () => {
    switch (operation.details.state) {
      case FLIGHT_STATES.ACTIVATED: {
        return `rgb(${HIGHLIGHT_COLORS.green.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.CONTINGENT: {
        return `rgb(${HIGHLIGHT_COLORS.red.slice(0, 3).join(",")})`;
      }
      case FLIGHT_STATES.NON_CONFORMANT: {
        return `rgb(${HIGHLIGHT_COLORS.orange.slice(0, 3).join(",")})`;
      }

      default:
        return "white";
    }
  };
  return (
    <Paper>
      <Grid
        container
        spacing={0}
        style={{
          padding: "5px",
          backgroundColor: backgroundColor(),
        }}
        wrap="nowrap"
      >
        <Grid
          item
          container
          xs={2}
          alignItems="space-between"
          justifyContent="center"
        >
          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                width: "20px",
              }}
              color="primary"
              onClick={handleStartClick}
              variant="contained"
            >
              Takeoff
            </Button>
          </div>
          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                width: "20px",
              }}
              color="primary"
              onClick={handleLandClick}
              variant="contained"
            >
              Landed
            </Button>
          </div>
          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                width: "20px",
              }}
              variant="contained"
              color="primary"
              onClick={handleContingentClick}
            >
              Contingent
            </Button>
          </div>
        </Grid>
        <Grid
          item
          style={{
            width: "100%",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
        >
          <OperationDetails operation={operation} platformData={platformData} />
          <Typography
            style={{ fontWeight: "bold", color: "primary", fontSize: "12px" }}
          >
            Ops State - {operation.details.state}
          </Typography>
          <Typography
            style={{ fontWeight: "bold", color: "primary", fontSize: "12px" }}
          >
            Ops Type - {operation.details.type.toUpperCase()}
          </Typography>
          <TelemetryDetails
            telemetryData={telemetryData}
            platformData={platformData}
          />
        </Grid>

        <Grid
          item
          container
          xs={2}
          alignItems="space-between"
          justifyContent="center"
        >
          {/* <div style={{textAlign: 'right'}}>
            <VisabilityIcon iconVisability={iconVisability} handleVisibilityClick={handleVisibilityClick}/>
          </div> */}

          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",
                backgroundColor: highlighted ? "blue" : "white",
                color: highlighted ? "white" : "black",
              }}
              variant="contained"
              onClick={() => {
                onFocus(operation);
              }}
            >
              Focus
            </Button>
          </div>
          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",

                width: "20px",
              }}
              color="secondary"
              onClick={handleRemoveClick}
              variant="contained"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              style={{
                fontSize: "9px",
                fontWeight: "bold",

                width: "20px",
              }}
              variant="contained"
              onClick={handleDownloadClick}
            >
              Download
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function OperationsControlComponent({
  operationsData,
  telemetryData,
  selectedPlatformData,
  onRemoveClick,
  onUpdateOperationState,
  onVisabilityClick,
}) {
  const [updatingOperation, setUpdatingOperation] = useState(false);

  return (
    <LoadingOverlay
      active={updatingOperation}
      spinner
      text="Updating Operation State..."
    >
      <OperationControlList
        operationsData={operationsData}
        telemetryData={telemetryData}
        selectedPlatformData={selectedPlatformData}
        onRemoveClick={onRemoveClick}
        onUpdateOperationState={onUpdateOperationState}
        onVisabilityClick={onVisabilityClick}
        setUpdatingOperation={setUpdatingOperation}
      />
    </LoadingOverlay>
  );
}
