import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import CreateOperationForm from "../CreateOperationForm";
import { getWSService } from "../../../services/websocket";
import WebsocketAlert from "../../../components/WebsocketAlert";

function CreateOperationComponent(props) {
  const {
    selectedWaypoints,
    selectedAreaWaypoints,
    onUpdateSelectedWaypoints,
    onUpdateSelectedAreaWaypoints,
    emergencyLanding,
    mapViewController,
    onEmergencyLandingUpdate,
  } = props;
  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);
  const [submittingOperation] = useState(false);

  useEffect(() => {
    enableSocketConnection();
  }, []);

  const enableSocketConnection = () => {
    const connection = getWSService();
    connection.addMessageListener((message) => {
      if (!message || !message.type) return;
      if (message.type === "state_change") {
        setWebsocketAlertOpen(true);
        setWebsocketMessage(message);
      }
    });
  };

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  return (
    <LoadingOverlay
      active={submittingOperation}
      spinner
      text="Submitting Operation..."
    >
      <CreateOperationForm
        onEmergencyLandingUpdate={onEmergencyLandingUpdate}
        emergencyLanding={emergencyLanding}
        mapViewController={mapViewController}
        selectedWaypoints={selectedWaypoints}
        selectedAreaWaypoints={selectedAreaWaypoints}
        onUpdateSelectedWaypoints={onUpdateSelectedWaypoints}
        onUpdateSelectedAreaWaypoints={onUpdateSelectedAreaWaypoints}
      />

      <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      />
    </LoadingOverlay>
  );
}

export default CreateOperationComponent;
