import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../actions";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt(token) {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const useCognitoAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const search = new URLSearchParams(window.location.hash.slice(1));
    const params = new Proxy(search, {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const accessToken =
      params.access_token || localStorage.getItem("accessToken");
    const idToken = params.id_token || localStorage.getItem("idToken");
    if (accessToken) localStorage.setItem("accessToken", accessToken);
    if (idToken) localStorage.setItem("idToken", idToken);
    const userInfo = parseJwt(idToken);
    dispatch(
      setAuth({
        accessToken,
        userInfo,
        isOperator: userInfo?.["cognito:groups"].includes("operator"),
        isAuthority: userInfo?.["cognito:groups"].includes("authority"),
        isLoggedIn: !!accessToken,
      })
    );
  }, []);

  const logout = () => {
    localStorage.clear();
    dispatch(
      setAuth({
        accessToken: "",
        idObject: null,
        isOperator: false,
        isAuthority: false,
        isLoggedIn: false,
      })
    );
  };

  return {
    accessToken: auth.accessToken,
    isLoggedIn: auth.isLoggedIn,
    username: auth.userInfo?.["cognito:username"],
    isOperator: auth.isOperator,
    isAuthority: auth.isAuthority,
    logout,
  };
};

export default useCognitoAuth;
