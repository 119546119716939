export const FLIGHT_STATES = {
  ACTIVATED: "Activated",
  ACCEPTED: "Accepted",
  ENDED: "Ended",
  CONTINGENT: "Contingent",
  NON_CONFORMANT: "NonConforming",
};
export const HIGHLIGHT_COLORS = {
  gray: [105, 105, 105, 0.2],
  blue: [0, 0, 255, 0.1],
  orange: [200, 150, 79, 0.2],
  green: [0, 255, 0, 0.3],
  red: [255, 0, 0, 0.1],
};
