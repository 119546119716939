import React, { useEffect, useMemo, useRef } from "react";
import ArcGISMap from "@arcgis/core/Map";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection";
import SceneView from "@arcgis/core/views/SceneView";
import Sketch from "@arcgis/core/widgets/Sketch";
import "./custom-acrgis.css";
import Graphic from "@arcgis/core/Graphic";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import Search from "@arcgis/core/widgets/Search";
import { useSelector } from "react-redux";
import createShipSymbols from "../../lib/arcgis/drawShipSymbols";
import createFlightOperationVolumeSymbols from "../../lib/arcgis/drawFlightOperationVolumeSymbols";
import createStaticConstraintVolume from "../../lib/arcgis/drawStaticConstraints";

function SimpleMapComponent(props) {
  const {
    onSelectWaypoints,
    onSelectAreaWaypoints,
    onSelectEmergencyLanding,
    updatedSelectedWaypoints,
    updatedSelectedAreaWaypoints,
    setMapViewController,
    constraints,
    selectedFlightData,
    updatedEmergencyLanding,
  } = props;

  const sketchLayer = useRef(new GraphicsLayer());
  const constraintsLayer = useRef(new GraphicsLayer());
  const flightOperationVolumeLayer = useRef(new GraphicsLayer());
  const shipData = useSelector((state) => state.shipData.data);
  const shipDataLayer = useRef(new GraphicsLayer());
  const staticConstraintsLayer = useRef(new GraphicsLayer());

  useEffect(() => {
    if (!updatedSelectedWaypoints.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "polyline") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });
    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "polyline", // autocasts as new Polyline()
          paths: updatedSelectedWaypoints,
        },
      })
    );
  }, [updatedSelectedWaypoints, sketchLayer]);

  useEffect(() => {
    if (!updatedSelectedAreaWaypoints.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "polygon") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });
    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "polygon", // Besides polygon - circle and rectangle are also under polygon
          rings: updatedSelectedAreaWaypoints,
        },
      })
    );
  }, [updatedSelectedAreaWaypoints, sketchLayer]);

  useEffect(() => {
    if (!updatedEmergencyLanding.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "point") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });

    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "point", // autocasts as new Polyline()
          longitude: updatedEmergencyLanding[0],
          latitude: updatedEmergencyLanding[1],
          hasZ: true,
          hasM: false,
          spatialReference: { wkid: 4326 },
        },
        symbol: {
          type: "simple-marker",
          style: "circle",
          size: 12,
          color: [255, 0, 0],
          outline: {
            color: [50, 50, 50],
            width: 5,
          },
        },
      })
    );
  }, [updatedEmergencyLanding, sketchLayer]);

  useEffect(() => {
    const drawConstraints = () => {
      if (constraints.length && constraintsLayer.current) {
        constraintsLayer.current.removeAll();
        for (const constraint of constraints) {
          for (const y of constraint.extents) {
            const coords = y.volume.outline_polygon.coordinates[0];
            coords.forEach((e) => {
              const z_val = y.volume.altitude_lower.value;
              e[2] = z_val;
            });

            const polygon = {
              type: "polygon",
              rings: coords,
              hasZ: true,
              z: y.volume.altitude_upper.value,
              spatialReference: { wkid: 4326 },
            };
            const polygonGraphic = new Graphic({
              geometry: polygon,
              spatialReference: { wkid: 102100 },
              symbol: {
                type: "polygon-3d",
                symbolLayers: [
                  {
                    type: "extrude",
                    size:
                      y.volume.altitude_upper.value -
                      y.volume.altitude_lower.value,
                    material: {
                      color: [255, 0, 0, 0.2],
                    },
                  },
                ],
                outline: {
                  color: [255, 0, 0],
                  width: 1,
                },
              },
            });
            constraintsLayer.current.add(polygonGraphic);
          }
        }
      }
    };
    drawConstraints();
  }, [constraints, constraintsLayer]);
  // const visibleFlights = useMemo(
  //   () => selectedFlightData?.filter((x) => x.visable) || [],
  //   [selectedFlightData]
  // );

  useEffect(() => {
    shipDataLayer.current.removeAll();
    shipDataLayer.current.addMany(createShipSymbols(shipData));
  }, [shipDataLayer, shipData]);

  useEffect(() => {
    staticConstraintsLayer.current?.addMany(createStaticConstraintVolume());
  }, [staticConstraintsLayer]);

  useEffect(() => {
    if (!flightOperationVolumeLayer.current) return;
    flightOperationVolumeLayer.current.removeAll();
    // const flightVols = visibleFlights.map((x) => x.details.operation_volumes);
    flightOperationVolumeLayer.current.addMany(
      createFlightOperationVolumeSymbols(selectedFlightData)
    );
  }, [selectedFlightData, flightOperationVolumeLayer]);

  useEffect(() => {
    const map = new ArcGISMap({
      basemap: "gray-vector",
      ground: "world-elevation",
      // layers: [b]
    });

    // load the map view at the ref's DOM node
    // this.view = new MapView({
    const view = new SceneView({
      // for 3D map
      container: "simple-map",
      map,
      center: [103.86102387869263, 1.2693197438139838],
      zoom: 17,
    });

    map.add(sketchLayer.current);
    map.add(constraintsLayer.current);
    map.add(flightOperationVolumeLayer.current);
    map.add(shipDataLayer.current);
    map.add(staticConstraintsLayer.current);

    const searchWidget = new Search({
      view,
    });
    // Adds the search widget below other elements in
    // the top left corner of the view
    view.ui.add(searchWidget, {
      position: "top-left",
      index: 0,
    });

    view.when(() => {
      const sketch = new Sketch({
        // graphic will be selected as soon as it is created
        creationMode: "update",
        viewModel: new SketchViewModel({
          layer: sketchLayer.current,
          view,
          pointSymbol: {
            type: "simple-marker",
            style: "circle",
            size: 12,
            color: [255, 0, 0],
            outline: {
              color: [50, 50, 50],
              width: 5,
            },
          },
        }),
      });

      // Listen to sketch widget's create event.
      sketch.on("create", (event) => {
        if (event.state === "start") {
          if (!event.graphic) {
            if (event.tool === "circle") {
              // TODO: To combine with polygon
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "circle") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
            if (event.tool === "rectangle") {
              // TODO: To combine with polygon
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "rectangle") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
          } else {
            if (event.graphic.geometry.type === "point") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "point") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectEmergencyLanding([]);
            }
            if (event.graphic.geometry.type === "polyline") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "polyline") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectWaypoints([]);
            }
            if (event.graphic.geometry.type === "polygon") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "polygon") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
          }
        }
        // check if the create event's state has changed to complete indicating
        // the graphic create operation is completed.
      });
      sketch.on("update", (event) => {
        if (event.state === "active" || event.state === "start") {
          if (event.graphics[0].geometry.type === "point") {
            const { latitude, longitude } = event.graphics[0].geometry;

            onSelectEmergencyLanding([longitude, latitude]);
          }
          if (event.graphics[0].geometry.type === "polyline") {
            const points = event.graphics[0].geometry.paths[0]
              .map((x, i) => event.graphics[0].geometry.getPoint(0, i))
              .map((p) => [p.longitude, p.latitude]);
            onSelectWaypoints(points);
          }
          if (event.graphics[0].geometry.type === "polygon") {
            const points = event.graphics[0].geometry.rings[0]
              .map((x, i) => event.graphics[0].geometry.getPoint(0, i))
              .map((p) => [p.longitude, p.latitude]);
            onSelectAreaWaypoints(points);
          }
        }
      });

      view.ui.add(sketch, "bottom-right");
    });

    projection.load();
    setMapViewController(view);
  }, []);

  return (
    <div
      style={{
        height: document.documentElement.clientHeight - 66,
        width: "100%",
      }}
      id="simple-map"
    />
  );
}

export default SimpleMapComponent;
