import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Chip } from "material-ui";
import { format } from "date-fns";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ActionMessagesTable(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Messages</TableCell>
            <TableCell align="right">Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const [ts, severity] = row.SK.split("#");
            return (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  <Chip
                    label="Alert"
                    color="danger"
                    variant="outlined"
                    style={{ background: "#95f985" }}
                  >
                    <span style={{ fontWeight: "bolder" }}> {severity}</span>
                  </Chip>
                </TableCell>
                <TableCell align="right">{row.data.message}</TableCell>

                {ts && (
                  <TableCell align="right">
                    {format(Number.parseInt(ts, 10), "yyyy-MM-dd HH:mm:ss")}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
