import { useMemo } from "react";
import { createInstance } from ".";
import useCognitoAuth from "../hooks/useCognitoAuth";

export const useApi = () => {
  const { accessToken } = useCognitoAuth();

  const instance = useMemo(() => createInstance(accessToken), [accessToken]);

  return instance;
};
