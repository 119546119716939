import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { useDispatch, useSelector } from "react-redux";

import Header from "../../components/Header";
import Map from "../../components/Map";
import FlightAuthorization from "./FlightAuthorization";
import { getWSService } from "../../services/websocket";
import WebsocketAlert from "../../components/WebsocketAlert";
import Tasks from "./Tasks";
import { useApi } from "../../api/useApi";
import PuckServer from "../../services/puckserver";
import { getShipData, setSnackbarMessage } from "../../actions";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    zIndex: 99,
    paddingTop: 66,
  },
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    position: "sticky",
    zIndex: theme.zIndex.drawer + 1,
  },
}));
function ProtectedComponent(props) {
  const [selectedFlightData, setSelectedFlightData] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [telemetryData, setTelemetryData] = useState([]);
  const [selectedPlatformData, setSelectedPlatformData] = useState([]);
  const [constraints, setConstraints] = useState([]);
  const [openFlightAuthorization, setOpenFlightAuthorization] = useState(false);
  const [showShipLayer, setShowShipLayer] = useState(false);
  const [showAdsbLayer, setShowAdsbLayer] = useState(false);
  const [showConstraintsLayer, setShowConstraintsLayer] = useState(true);
  const [showStaticConstraintsLayer, setShowStaticConstraintsLayer] =
    useState(true);
  const [messages, setMessages] = useState([]);
  const classes = useStyles();
  const api = useApi();
  const dispatch = useDispatch();

  const [updatedEmergencyLanding, setUpdatedEmergencyLanding] = useState([]);
  const [mapViewController, setMapViewController] = useState();
  const [emergencyLanding, setEmergencyLanding] = useState([]);
  const [selectedWaypoints, setSelectedWaypoints] = useState([]);
  const [updatedSelectedWaypoints, setUpdatedSelectedWaypoints] = useState([]);
  const [selectedAreaWaypoints, setSelectedAreaWaypoints] = useState([]);
  const [updatedSelectedAreaWaypoints, setUpdatedSelectedAreaWaypoints] =
    useState([]);
  // useEffect(() => {
  //   if (!websocketMessage || !websocketMessage.type) return;

  //   if (websocketMessage.type === "state_change") {
  //     const operationID = websocketMessage.operationID;
  //     const newState = websocketMessage.new_state;

  //     var operations = [...selectedFlightData];
  //     const index = operations.findIndex((x) => x.reference.id === operationID);
  //     const newWebSocketMessage = websocketMessage;
  //     if (index > -1) {
  //       operations[index].operation_state = newState;
  //       setSelectedFlightData(operations);
  //       setWebsocketAlertOpen(true);
  //       getPlatformCallSign(
  //         websocketMessage,
  //         operations[index].request.platform_uuid
  //       );
  //     }
  //   }
  // }, [websocketMessage]);

  const enableSocketConnection = () => {
    const connection = getWSService();
    connection.addMessageListener(handleMessage);
  };

  const handleMessage = (message) => {
    setWebsocketMessage(message);
  };

  const getUserOperations = async () => {
    try {
      const res = await api.getAllOperationSelection();

      const flightData = res.data.map((r) => r.operation_json);

      getPlatformData(flightData);
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedOperation = async () => {
    const res = await api.getAllOperationSelection();
    setSelectedFlightData(
      res.data.map(({ operation_json }) => operation_json).filter((x) => x)
    );
  };

  const getPlatformData = async (flightData) => {
    try {
      const platformIDs = flightData.map(
        (flight) => flight.request.platform_uuid
      );
      const platformData = await Promise.all(
        platformIDs.map((platformID) => api.getPlatform(platformID))
      );

      setSelectedPlatformData(platformData);
    } catch (err) {
      console.error(err);
    }
  };

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen((s) => !s);
  };

  const handleOpenFlightAuthorization = () => {
    setOpenFlightAuthorization((s) => !s);
  };

  useEffect(() => {
    if (!selectedFlightData) return undefined;
    const pollPuckForTelemetry = async (selectedflights) => {
      const [puckErr, puckResponse] = await PuckServer.getFlightTelemetry(
        selectedflights.details.puck_uuid
      );
      if (puckErr) {
        dispatch(
          setSnackbarMessage({
            open: true,
            message: puckErr,
            severity: "error",
          })
        );
      }
      // console.log(puckResponse)
      const resp = {
        gufi: selectedflights.reference.id,
        puckResponse,
      };
      return resp;
    };
    const poll = async () => {
      const respo = await Promise.all(
        selectedFlightData.map(pollPuckForTelemetry)
      );

      setTelemetryData([...respo.filter((r) => r)]);
    };

    const interval = setInterval(poll, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedFlightData, dispatch]);

  useEffect(() => {
    api.getLogMessages().then((res) => setMessages(res.data));
    getUserOperations();
    enableSocketConnection();
    const loadConstraints = async () => {
      try {
        const res = await api.getConstraints();
        setConstraints(res.data);
      } catch (err) {
        if (
          err?.response?.data?.message ===
          "No active constraints can be retrieved."
        )
          return;
        dispatch(
          setSnackbarMessage({
            message: err.response.data.message,
            severity: "error",
          })
        );

        setConstraints([]);
      }
    };
    loadConstraints();
    const interval = setInterval(loadConstraints, 60 * 1000);
    const pollUserOperations = setInterval(() => {
      getSelectedOperation();
    }, 1000);
    return () => {
      clearInterval(interval);
      clearInterval(pollUserOperations);
    };
  }, []);

  useEffect(() => {
    dispatch(getShipData());
    // TODO: make some adjustments here such as the long and lat can be by userclick?
    const getShipDataInterval = setInterval(() => {
      dispatch(getShipData());
    }, 1000 * 60); // Updates every 1 min
    return () => {
      clearInterval(getShipDataInterval);
    };
  }, []);

  const toggleShowShipLayer = () => {
    setShowShipLayer((s) => !s);
  };

  const toggleShowAdsbLayer = () => {
    setShowAdsbLayer((s) => !s);
  };

  const toggleShowConstraintsLayer = () => {
    setShowConstraintsLayer((s) => !s);
  };

  const toggleShowStaticConstraintsLayer = () => {
    if (showStaticConstraintsLayer) {
      setShowStaticConstraintsLayer(false);
    } else {
      setShowStaticConstraintsLayer(true);
    }
  };

  return (
    <div>
      <div className={classes.appBar}>
        <Header
          messages={messages}
          onOpenNotification={() => {
            api.getLogMessages().then((res) => setMessages(res.data));
          }}
          onOpenDrawer={handleOpenDrawer}
          onOpenFlightAuthorization={handleOpenFlightAuthorization}
        />
      </div>
      <Drawer
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={openFlightAuthorization}
      >
        <FlightAuthorization
          mapViewController={mapViewController}
          selectedFlightData={selectedFlightData}
          constraints={constraints}
          onEmergencyLandingUpdateMove={setUpdatedEmergencyLanding}
          emergencyLandingMove={emergencyLanding}
          selectedWaypointsMove={selectedWaypoints}
          onUpdateSelectedWaypointsMove={setUpdatedSelectedWaypoints}
          selectedAreaWaypointsMove={selectedAreaWaypoints}
          onUpdateSelectedAreaWaypointsMove={setUpdatedSelectedAreaWaypoints}
        />
      </Drawer>
      <div>
        <Map
          selectedFlightData={selectedFlightData}
          telemetryData={telemetryData}
          constraints={constraints}
          showShipLayer={showShipLayer}
          showAdsbLayer={showAdsbLayer}
          showConstraintsLayer={showConstraintsLayer}
          showStaticConstraintsLayer={showStaticConstraintsLayer}
          updatedEmergencyLanding={updatedEmergencyLanding}
          setMapViewController={setMapViewController}
          onSelectEmergencyLanding={setEmergencyLanding}
          onSelectWaypoints={setSelectedWaypoints}
          updatedSelectedWaypoints={updatedSelectedWaypoints}
          onSelectAreaWaypoints={setSelectedAreaWaypoints}
          updatedSelectedAreaWaypoints={updatedSelectedAreaWaypoints}
        />
        {/* <div
          style={{
            position: "absolute",
            zIndex: 10,
            top: 60,
            left: "30vw",
            right: 0,
            bottom: 0,
          }}
        >
          <SimpleMapComponent onSelectPolyline={handleSelectPolyline} />
        </div> */}
      </div>
      <Tasks
        onDrawerClose={() => setIsDrawerOpen(false)}
        isDrawerOpen={isDrawerOpen}
        telemetryData={telemetryData}
        selectedFlightData={selectedFlightData}
        selectedPlatformData={selectedPlatformData}
        onRemoveOperation={getUserOperations}
        onUpdateOperationState={getUserOperations}
        toggleShowShipLayer={toggleShowShipLayer}
        toggleShowAdsbLayer={toggleShowAdsbLayer}
        toggleShowConstraintsLayer={toggleShowConstraintsLayer}
        toggleShowStaticConstraintsLayer={toggleShowStaticConstraintsLayer}
      />

      <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={messageDisplay}
      />
    </div>
  );
}

export default ProtectedComponent;
