import { GET_USER_OPERATION_SUCCESS } from "../../actions/operations";

const operationsReducer = (state = { operations: [] }, action) => {
  switch (action.type) {
    case GET_USER_OPERATION_SUCCESS: {
      return {
        ...state,
        operations: action.payload,
      };
    }
    default:
      return state;
  }
};

export default operationsReducer;
