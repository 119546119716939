import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";

import { setSnackbarMessage } from "../../../actions";
import { useApi } from "../../../api/useApi";

function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${  minutes}` : minutes;
  const strTime = `${hours  }:${  minutes  } ${  ampm}`;
  return strTime;
}

function CurrentInfo({ weatherData }) {
  if (!weatherData) return <div />;

  const currentInfo = weatherData.current;
  const weather = currentInfo.weather[0];

  return (
    <Grid
      container
      spacing={0}
      style={{
        paddingBottom: "3px",
        paddingTop: "3px",
        borderBottom: "1px solid black",
      }}
    >
      <Grid item xs={7} style={{ marginTop: "3px" }}>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: "blue", fontSize: "12px" }}
        >
          {new Date(currentInfo.dt * 1000.0).toLocaleString("en-GB")}
        </Typography>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: "blue", fontSize: "12px" }}
        >
          {weather.main}
        </Typography>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: "blue", fontSize: "12px" }}
        >
          Wind: {currentInfo.wind_speed} knots at {currentInfo.wind_deg}&#xB0;
        </Typography>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: "blue", fontSize: "12px" }}
        >
          Temp: {currentInfo.temp} &#xB0;C
        </Typography>
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", color: "blue", fontSize: "12px" }}
        >
          Rain: {currentInfo.rain ? currentInfo.rain["1h"] : 0}mm
        </Typography>
      </Grid>
    </Grid>
  );
}

function HourlyForecast({ weatherData }) {
  if (!weatherData) return <div />;

  const hourlyData = weatherData.hourly.slice(0, 6);

  return (
    <div style={{ marginTop: "3px" }}>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow
              size="small"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                size="small"
                align="center"
                style={{ fontWeight: "bold", fontSize: "12px", padding: "0px" }}
              >
                Time{" "}
              </TableCell>
              <TableCell
                size="small"
                align="center"
                style={{ fontWeight: "bold", fontSize: "12px", padding: "0px" }}
              >
                Condition{" "}
              </TableCell>
              <TableCell
                size="small"
                align="center"
                style={{ fontWeight: "bold", fontSize: "12px", padding: "0px" }}
              >
                Wind{" "}
              </TableCell>
              <TableCell
                size="small"
                align="center"
                style={{ fontWeight: "bold", fontSize: "12px", padding: "0px" }}
              >
                Temp&#xB0;C
              </TableCell>
              <TableCell
                size="small"
                align="center"
                style={{ fontWeight: "bold", fontSize: "12px", padding: "0px" }}
              >
                Rain(mm){" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hourlyData.map((data, index) => (
                <TableRow
                  size="small"
                  key={index.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    size="small"
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    {formatAMPM(new Date(data.dt * 1000.0))}{" "}
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      padding: "0px",
                    }}
                  >
                    {data.weather[0].main}
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    {data.wind_speed} knots, {data.wind_deg}&#xB0;{" "}
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    {data.temp}{" "}
                  </TableCell>
                  <TableCell
                    size="small"
                    align="center"
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    {data.rain ? data.rain["1h"] : 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function WeatherComponent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [weatherData, setWeatherData] = React.useState(null);
  const api = useApi();
  React.useEffect(() => {
    // make some adjustments here such as the long and lat can be by userclick?
    const getWeatherInterval = setInterval(() => {
      getWeatherData(103.864935, 1.270966);
    }, 1000 * 60);
    return () => {
      clearInterval(getWeatherInterval);
    };
  }, []);

  const getWeatherData = async (longitude, latitude) => {
    setLoading(true);

    try {
      const response = await api.weather.getWeatherData({
        longitude,
        latitude,
      });
      if (response.data && response.data.hourly) {
        setWeatherData(response.data);
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          open: true,
          message: "Unable to load weather data",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text="Retrieving weather data..."
    >
      <Paper style={{ background: "lightgrey" }}>
        <CurrentInfo weatherData={weatherData} />
        <HourlyForecast weatherData={weatherData} />
      </Paper>
    </LoadingOverlay>
  );
}

export default WeatherComponent;
