import { SET_AUTH } from "../../actions";

const initState = {
  accessToken: null,
  idToken: null,
  user: null,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_AUTH: {
      return {
        ...state,
        ...action.data,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
