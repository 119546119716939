import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Grid from "@material-ui/core/Grid";

function OverlayManagerComponent({  toggleShowShipLayer, 
                                    toggleShowAdsbLayer,
                                    toggleShowConstraintsLayer,
                                    toggleShowStaticConstraintsLayer }) {
  const [AISvalue, setAISvalue] = useState(false);
  const [ADSBvalue, setADSBvalue] = useState(false); 
  const [ConstraintsValue, setConstraintsValue] = useState(true);
  const [StaticConstraintsValue, setStaticConstraintsValue] = useState(true);

  const handleConstraintsClick = () => {
    toggleShowConstraintsLayer();
    if (ConstraintsValue) {
      setConstraintsValue(false)
    } else {
      setConstraintsValue(true)
    }
  };

  const handleStaticConstraintsClick = () => {
    toggleShowStaticConstraintsLayer();
    if (StaticConstraintsValue) {
      setStaticConstraintsValue(false)
    } else {
      setStaticConstraintsValue(true)
    }
  };

  const handleAdsbclick = () => {
    toggleShowAdsbLayer();
    if (ADSBvalue) {
      setADSBvalue(false)
    } else {
      setADSBvalue(true)
    }
  };

  const handleVesselClick = () => {
    toggleShowShipLayer();
    if (AISvalue) {
      setAISvalue(false)
    } else {
      setAISvalue(true)
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ToggleButton
            size="small"
            style={{ width: "150px", marginLeft: "50px" }}
            value="AIS"
            selected={AISvalue}
            onChange={handleVesselClick}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
            >
              {" "}
              AIS{" "}
            </Typography>
          </ToggleButton>
          <ToggleButton
            size="small"
            style={{ width: "150px", marginLeft: "50px" }}
            value="AIS"
            selected={StaticConstraintsValue}
            onChange={handleStaticConstraintsClick}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
            >
              {" "}
              Static Constraints{" "}
            </Typography>
          </ToggleButton>
          <ToggleButton
            size="small"
            style={{ width: "150px", marginLeft: "50px" }}
            value="ADSB"
            selected={ADSBvalue}
            onChange={handleAdsbclick}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
            >
              {" "}
              ADS-B{" "}
            </Typography>
          </ToggleButton>

          <ToggleButton
            size="small"
            disabled
            style={{ width: "150px", marginLeft: "50px" }}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "grey" }}
            >
              {" "}
              Operations Volume{" "}
            </Typography>
          </ToggleButton>

          <ToggleButton
            size="small"
            style={{ width: "150px", marginLeft: "50px" }}
            value="CONSTRAINTS"
            selected={ConstraintsValue}
            onChange={handleConstraintsClick}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
            >
              {" "}
              Constraints{" "}
            </Typography>
          </ToggleButton>

          <ToggleButton
            size="small"
            disabled
            style={{ width: "150px", marginLeft: "50px" }}
          >
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "grey" }}
            >
              {" "}
              Building{" "}
            </Typography>
          </ToggleButton>
        </Grid>
      </Grid>
  );
}
export default OverlayManagerComponent;
