import { useState } from "react";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import FlatButton from "material-ui/FlatButton";
import { MenuItem } from "material-ui";

import Popover from "@material-ui/core/Popover";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ButtonGroup } from "@material-ui/core";
import { Notifications, ExitToApp } from "@material-ui/icons";

import ActionMessagesTable from "../ActionMessagesTable";
import useCognitoAuth from "../../hooks/useCognitoAuth";
import Schedules from "../Schedules";
import AssetManagement from "../AssetManagement";

const styles = {
  toolBar: {
    backgroundColor: "white",
    color: "black",
    height: "60px",
    margin: "auto",
    width: "100%",
  },
  menuLinks: {
    color: "black",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "5px",
  },
};

export default function HeaderComponent({
  onOpenDrawer,
  onOpenFlightAuthorization,
  messages,
  onOpenNotification,
}) {
  const [anchorAssetManagement, setAnchorAssetManagement] = useState(null);
  const [anchorFlightLogs, setAnchorFlightLogs] = useState(null);
  const [anchorIncidentReporting, setAnchorIncidentReporting] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notificationsAnchoringEl, setNotificationsAnchoringEl] =
    useState(null);

  const handleClick1 = (event) => {
    setAnchorAssetManagement(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorAssetManagement(null);
  };

  const openAssetManagement = Boolean(anchorAssetManagement);

  const handleClick2 = (event) => {
    setAnchorFlightLogs(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorFlightLogs(null);
  };

  const openFlightLogs = Boolean(anchorFlightLogs);

  const handleClick3 = (event) => {
    setAnchorIncidentReporting(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorIncidentReporting(null);
  };

  const openIncidentReporting = Boolean(anchorIncidentReporting);

  const handleClick4 = (event) => {
    onOpenFlightAuthorization();
  };

  const handleOpenNotifications = (open) => {
    setOpenNotifications(open);
    onOpenNotification();
  };

  const { logout, username, isOperator } = useCognitoAuth();
  return (
    <Toolbar style={styles.toolBar}>
      <ToolbarGroup>
        <MenuItem style={{ padding: 0 }}>
          {isOperator && (
            <div
              style={{
                objectFit: "contain",
                width: "120px",
                height: "60px",
                background: `url(/${
                  isOperator ? "operator" : "authority"
                }.png)`,
                backgroundSize: "cover",
                backgroundRepeat: "space",
              }}
            />
          )}
        </MenuItem>

        <MenuItem style={styles.menuLinks} onClick={handleClick1}>
          Asset Management
        </MenuItem>
        <Popover
          id="AssetManagement"
          open={openAssetManagement}
          anchorEl={anchorAssetManagement}
          onClose={handleClose1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <AssetManagement />
        </Popover>

        <MenuItem
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
          onClick={handleClick4}
        >
          Flight Authorization
        </MenuItem>

        <MenuItem
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
          onClick={handleClick2}
        >
          Flight Logs
        </MenuItem>
        <Popover
          id="FlightLogs"
          open={openFlightLogs}
          anchorEl={anchorFlightLogs}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Schedules />
        </Popover>

        <MenuItem
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
          onClick={handleClick3}
        >
          Incident Reporting
        </MenuItem>
        <Popover
          id="IncidentReporting"
          open={openIncidentReporting}
          anchorEl={anchorIncidentReporting}
          onClose={handleClose3}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            <Typography>Coming Soon...</Typography>
          </Box>
        </Popover>

        <MenuItem
          style={{
            color: "black",
            fontSize: "12px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
          onClick={onOpenDrawer}
        >
          Operations Panel
        </MenuItem>
      </ToolbarGroup>

      <ToolbarGroup>
        <ButtonGroup>
          <FlatButton>{username}</FlatButton>
          <FlatButton
            id="notifications"
            icon={<Notifications color="action" />}
            onClick={(ev) => {
              setNotificationsAnchoringEl(ev.currentTarget);
              handleOpenNotifications(true);
            }}
          />
          <Popover
            open={openNotifications}
            anchorEl={notificationsAnchoringEl}
            onClose={() => handleOpenNotifications(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ marginTop: 8 }}
          >
            <Box style={{ width: 600 }}>
              <ActionMessagesTable data={messages} />
            </Box>
          </Popover>
          <FlatButton
            onClick={() => {
              logout();
            }}
            icon={<ExitToApp />}
          />
        </ButtonGroup>
      </ToolbarGroup>
    </Toolbar>
    // </AppBar>
  );
}
