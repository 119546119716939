import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import subscriptionStatus from "./subscriptions";
import snackbar from "./snackbar";
import shipData from "./shipData";
import operationsReducer from "./operations";
import mapsReducer from "./maps";
import authReducer from "./auth";

const reducers = combineReducers({
  auth: authReducer,
  form: formReducer,
  operations: operationsReducer,
  subscriptionStatus,
  snackbar,
  shipData,
  maps: mapsReducer,
});

export default reducers;
