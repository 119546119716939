import { useEffect, useState, useMemo, useRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import "./custom-acrgis.css";
import { useDispatch, useSelector } from "react-redux";
import ArcGISMap from "@arcgis/core/Map";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as projection from "@arcgis/core/geometry/projection";
import SceneView from "@arcgis/core/views/SceneView";
import Sketch from "@arcgis/core/widgets/Sketch";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import PortalSource from "@arcgis/core/widgets/BasemapGallery/support/PortalBasemapsSource";
import Measurement from "@arcgis/core/widgets/DirectLineMeasurement3D";
import Search from "@arcgis/core/widgets/Search";
import Zoom from "@arcgis/core/widgets/Zoom";
import Graphic from "@arcgis/core/Graphic";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import {
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
} from "../../config/flightStatusColor";
import createShipSymbols from "../../lib/arcgis/drawShipSymbols";
import createFlightOperationVolumeSymbols from "../../lib/arcgis/drawFlightOperationVolumeSymbols";
import createStaticConstraintVolume from "../../lib/arcgis/drawStaticConstraints";
import { useApi } from "../../api/useApi";
import {
  getShipData,
  setTrackingMapView,
  setSnackbarMessage,
} from "../../actions";
// import { Zoom } from "@material-ui/core";
const ALLOWED_BASEMAP_TITLES = [
  "Dark Gray Canvas",
  "Light Gray Canvas",
  "Streets",
  "Imagery",
  "Topographic",
];

function MapComponent(props) {
  const {
    selectedFlightData,
    telemetryData,
    constraints,
    mapHeight = document.documentElement.clientHeight - 60,
    showShipLayer,
    showAdsbLayer,
    showConstraintsLayer,
    showStaticConstraintsLayer,

    updatedEmergencyLanding,
    setMapViewController,
    onSelectEmergencyLanding,
    onSelectWaypoints,
    updatedSelectedWaypoints,
    onSelectAreaWaypoints,
    updatedSelectedAreaWaypoints,
  } = props;
  const shipData = useSelector((state) => state.shipData.data);
  const dispatch = useDispatch();
  const [flightOpertaionVolumeLayer, setFlightOpertaionVolumeLayer] =
    useState();
  const [constraintsLayer, setConstraintsLayer] = useState();
  const [staticConstraintsLayer, setStaticConstraintsLayer] = useState();
  const [shipLayer, setShipLayer] = useState();
  const [telemetryLayer, setTelemetryLayer] = useState();
  const [adsbLayer, setAdsbLayer] = useState();
  const [coord, setCoord] = useState("");
  const api = useApi();
  const trackingMapView = useSelector((s) => s.maps.trackingMapView);

  const sketchLayer = useRef(new GraphicsLayer());

  useEffect(() => {
    const map = new ArcGISMap({
      basemap: "gray-vector",
      ground: "world-elevation",
    });

    const view = new SceneView({
      // for 3D map
      container: "viewDiv",
      map,
      center: [103.86102387869263, 1.2693197438139838],
      zoom: 17,
    });
    view.environment.lighting.directShadowsEnabled = true;
    dispatch(setTrackingMapView(view));

    // const sketchLayer = new GraphicsLayer();
    const _flightOpertaionVolumeLayer = new GraphicsLayer({
      title: "FlightOperationVolume",
    });
    const _shipLayer = new GraphicsLayer();
    const _telemetryLayer = new GraphicsLayer();
    const _constraintsLayer = new GraphicsLayer();
    const _staticConstraintsLayer = new GraphicsLayer();
    const _adsbLayer = new GraphicsLayer();

    const searchWidget = new Search({
      view,
    });
    // Adds the search widget below other elements in
    // the top left corner of the view
    view.ui.add(searchWidget, {
      position: "top-right",
      index: 0,
    });

    // const zoom = new Zoom({
    //   view: view,
    //   layout: "horizontal"
    // });
    // view.ui.add(zoom, {
    //   position: "top-right"
    // });

    view.on("mouse-wheel", (event) => {
      for (const graphic of _telemetryLayer.graphics) {
        const pointSymbol = graphic.symbol.clone();
        const symbolLayer = pointSymbol.symbolLayers.getItemAt(0);
        const zoomFactor = (
          thresholdZoom = 16.5,
          maxZoom = 17,
          minZoom = 10,
          minScale = 1,
          maxScale = 40
        ) => {
          if (view.zoom > thresholdZoom) return minScale * 17;
          return (
            Math.max(
              minScale,
              (Math.abs(view.zoom - thresholdZoom) * (maxScale - minScale)) /
                (thresholdZoom - minZoom)
            ) * 17
          );
        };

        symbolLayer.width = 1 * zoomFactor();
        symbolLayer.height = 0.4 * zoomFactor();
        symbolLayer.depth = 1 * zoomFactor();

        pointSymbol.symbolLayers = [symbolLayer];
        // UPDATE SYMBOL //
        graphic.symbol = pointSymbol;
      }
    });
    // map.add(sketchLayer);
    map.add(sketchLayer.current);
    map.add(_flightOpertaionVolumeLayer);
    map.add(_shipLayer);
    map.add(_telemetryLayer);
    map.add(_constraintsLayer);
    map.add(_staticConstraintsLayer);
    map.add(_adsbLayer);

    // const sketch = new Sketch({
    //   layer: sketchLayer,
    //   view: view,
    // });

    view.when(() => {
      const sketch = new Sketch({
        // graphic will be selected as soon as it is created
        creationMode: "update",
        viewModel: new SketchViewModel({
          layer: sketchLayer.current,
          view,
          pointSymbol: {
            type: "simple-marker",
            style: "circle",
            size: 12,
            color: [255, 0, 0],
            outline: {
              color: [50, 50, 50],
              width: 5,
            },
          },
        }),
      });
      const measurement = new Measurement({
        view,
        activeTool: "distance",
      });

      sketch.when((y) => {
        const b = document.createElement("img");
        b.src = "ruler.svg";
        b.width = "24";
        b.style = "cursor:pointer";
        b.addEventListener("click", () => {
          if (view.ui.find(measurement.id)) {
            view.ui.remove(measurement);
          } else {
            view.ui.add(measurement, "top-right");
          }
        });
        y.container
          .querySelectorAll(".esri-sketch__tool-section")[1]
          .appendChild(b);
      });

      measurement.when((y) => {
        const button = y.container?.querySelector("button");
        if (button) button.click();
      });
      const source = new PortalSource({
        // filtering portal basemaps
        filterFunction: (basemap) =>
          ALLOWED_BASEMAP_TITLES.indexOf(basemap.portalItem.title) > -1,
      });
      const basemaps = new BasemapGallery({
        view,
        source,
      });

      // Listen to sketch widget's create event.
      sketch.on("create", (event) => {
        if (event.state === "start") {
          if (!event.graphic) {
            if (event.tool === "circle") {
              // TODO: To combine with polygon
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "circle") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
            if (event.tool === "rectangle") {
              // TODO: To combine with polygon
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "rectangle") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
          } else {
            if (event.graphic.geometry.type === "point") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "point") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectEmergencyLanding([]);
            }
            if (event.graphic.geometry.type === "polyline") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "polyline") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectWaypoints([]);
            }
            if (event.graphic.geometry.type === "polygon") {
              sketchLayer.current.graphics.forEach((graphic) => {
                if (graphic.geometry.type === "polygon") {
                  sketchLayer.current.graphics.remove(graphic);
                }
              });
              onSelectAreaWaypoints([]);
            }
          }
        }
        // check if the create event's state has changed to complete indicating
        // the graphic create operation is completed.
      });
      sketch.on("update", (event) => {
        if (event.state === "active" || event.state === "start") {
          if (event.graphics[0].geometry.type === "point") {
            const { latitude, longitude } = event.graphics[0].geometry;

            onSelectEmergencyLanding([longitude, latitude]);
          }
          if (event.graphics[0].geometry.type === "polyline") {
            const points = event.graphics[0].geometry.paths[0]
              .map((x, i) => event.graphics[0].geometry.getPoint(0, i))
              .map((p) => [p.longitude, p.latitude]);
            onSelectWaypoints(points);
          }
          if (event.graphics[0].geometry.type === "polygon") {
            const points = event.graphics[0].geometry.rings[0]
              .map((x, i) => event.graphics[0].geometry.getPoint(0, i))
              .map((p) => [p.longitude, p.latitude]);
            onSelectAreaWaypoints(points);
          }
        }
      });

      view.ui.add(basemaps, "bottom-right");
      view.ui.add(sketch, "bottom-right");
    });

    setFlightOpertaionVolumeLayer(_flightOpertaionVolumeLayer);
    setShipLayer(_shipLayer);
    setTelemetryLayer(_telemetryLayer);
    setConstraintsLayer(_constraintsLayer);
    setStaticConstraintsLayer(_staticConstraintsLayer);
    setAdsbLayer(_adsbLayer);
    projection.load();
    setMapViewController(view);
  }, [dispatch]);

  const visibleFlights = useMemo(
    () => selectedFlightData?.filter((x) => x.visable) || [],
    [selectedFlightData]
  );

  useEffect(() => {
    staticConstraintsLayer?.addMany(createStaticConstraintVolume());
  }, [staticConstraintsLayer]);

  useEffect(() => {
    if (staticConstraintsLayer) {
      console.log("cek constrain laye", staticConstraintsLayer);
      staticConstraintsLayer.visible = showStaticConstraintsLayer;
    }
  }, [showStaticConstraintsLayer, staticConstraintsLayer]);

  useEffect(() => {
    shipLayer?.removeAll();
    shipLayer?.addMany(createShipSymbols(shipData));
  }, [shipData, shipLayer]);

  useEffect(() => {
    if (shipLayer) {
      shipLayer.visible = showShipLayer;
    }
  }, [showShipLayer, shipLayer]);

  useEffect(() => {
    const redrawAdsbData = async () => {
      if (adsbLayer) adsbLayer.removeAll();
      if (!adsbLayer || !showAdsbLayer) return;

      // Check that the projection library has loaded
      if (!projection.isLoaded()) {
        while (!projection.isLoaded()) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((r) => {
            setTimeout(r, 100);
          });
        }
      }

      try {
        const response = await api.getAdsb();
        if (response.data) {
          // setPilotList(response.data);
        }
      } catch (err) {
        dispatch(
          setSnackbarMessage({
            message: err.response.data.message,
            severity: "error",
          })
        );
      }
    };
    redrawAdsbData();
  }, [adsbLayer, showAdsbLayer]);

  useEffect(() => {
    if (!flightOpertaionVolumeLayer) return;
    flightOpertaionVolumeLayer.removeAll();

    flightOpertaionVolumeLayer.addMany(
      createFlightOperationVolumeSymbols(selectedFlightData)
    );
  }, [selectedFlightData, flightOpertaionVolumeLayer]);

  useEffect(() => {
    if (!telemetryLayer || !trackingMapView) return;

    const redrawTelemetryData = async () => {
      if (telemetryLayer) telemetryLayer.removeAll();
      if (!telemetryData) return;
      const getZoomFactor = (
        thresholdZoom = 16.5,
        maxZoom = 17,
        minZoom = 10,
        minScale = 1,
        maxScale = 40
      ) => {
        if (trackingMapView.zoom > thresholdZoom) return minScale * 17;
        return (
          Math.max(
            minScale,
            (Math.abs(trackingMapView.zoom - thresholdZoom) *
              (maxScale - minScale)) /
              (thresholdZoom - minZoom)
          ) * 17
        );
      };
      const zoomFactor = getZoomFactor();
      for (const telemetry of telemetryData) {
        if (!telemetry?.puckResponse?.position) continue;

        const { lat, lng, alt } = telemetry.puckResponse.position;
        if (!lat || !lng || !alt) continue;

        const heading = telemetry.puckResponse.track;
        let color = "gray";
        const operation = selectedFlightData.find(
          (op) => op.reference.id === telemetry.gufi
        );

        switch (operation.details.state) {
          case FLIGHT_STATES.CONTINGENT: {
            color = "red";
            break;
          }
          case FLIGHT_STATES.ACTIVATED: {
            color = "limegreen";
            break;
          }
          default:
            color = "gray";
            break;
        }
        telemetryLayer.add(
          new Graphic({
            geometry: {
              type: "point",
              y: lat,
              x: lng,
              hasZ: true,
              hasM: false,
              z: alt,
            },
            symbol: {
              type: "point-3d",

              symbolLayers: [
                {
                  type: "object", // autocasts as new ObjectSymbol3DLayer()
                  width: zoomFactor * 1, // diameter of the object from east to west in meters
                  height: zoomFactor * 0.4, // height of object in meters
                  depth: zoomFactor * 1, // diameter of the object from north to south in meters
                  resource: {
                    href: "./arrow.glb",
                  },
                  heading,
                  material: {
                    color,
                  },
                },
              ],
            },
          })
        );
      }
    };
    redrawTelemetryData();
  }, [telemetryData, telemetryLayer, trackingMapView]);

  useEffect(() => {
    const drawConstraints = () => {
      if (!showConstraintsLayer) {
        constraintsLayer.removeAll();
        return;
      }

      if (constraints.length && constraintsLayer && showConstraintsLayer) {
        constraintsLayer.removeAll();
        for (const constraint of constraints) {
          for (const y of constraint.extents) {
            const coords = y.volume.outline_polygon.coordinates[0];
            coords.forEach((e) => {
              e[2] = y.volume.altitude_lower.value;
            });

            const polygon = {
              type: "polygon",
              rings: coords,
              hasZ: true,
              z: y.volume.altitude_upper.value,
            };
            const polygonGraphic = new Graphic({
              geometry: polygon,
              symbol: {
                type: "polygon-3d",

                symbolLayers: [
                  {
                    type: "extrude",
                    castShadows: false,
                    size:
                      y.volume.altitude_upper.value -
                      y.volume.altitude_lower.value,
                    material: {
                      color: [255, 0, 0, 0.2],
                    },
                  },
                ],
                outline: {
                  color: [255, 0, 0],
                  width: 1,
                },
              },
            });
            constraintsLayer.add(polygonGraphic);
          }
        }
      }
    };
    drawConstraints();
  }, [constraints, constraintsLayer, showConstraintsLayer]);

  useEffect(() => {
    if (!updatedEmergencyLanding.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "point") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });

    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "point", // autocasts as new Polyline()
          longitude: updatedEmergencyLanding[0],
          latitude: updatedEmergencyLanding[1],
          hasZ: true,
          hasM: false,
          spatialReference: { wkid: 4326 },
        },
        symbol: {
          type: "simple-marker",
          style: "circle",
          size: 12,
          color: [255, 0, 0],
          outline: {
            color: [50, 50, 50],
            width: 5,
          },
        },
      })
    );
  }, [updatedEmergencyLanding, sketchLayer]);

  useEffect(() => {
    if (!updatedSelectedWaypoints.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "polyline") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });
    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "polyline", // autocasts as new Polyline()
          paths: updatedSelectedWaypoints,
        },
      })
    );
  }, [updatedSelectedWaypoints, sketchLayer]);
  useEffect(() => {
    if (!updatedSelectedAreaWaypoints.length || !sketchLayer.current) return;
    sketchLayer.current.graphics.forEach((graphic) => {
      if (graphic.geometry.type === "polygon") {
        sketchLayer.current.graphics.remove(graphic);
      }
    });
    sketchLayer.current.add(
      new Graphic({
        geometry: {
          type: "polygon", // Besides polygon - circle and rectangle are also under polygon
          rings: updatedSelectedAreaWaypoints,
        },
      })
    );
  }, [updatedSelectedAreaWaypoints, sketchLayer]);

  return (
    <LoadingOverlay>
      <div style={{ height: mapHeight, width: "100%" }} id="viewDiv" />
    </LoadingOverlay>
  );
}

export default MapComponent;
