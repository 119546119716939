import { useEffect } from "react";
import "./App.css";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Outlet,
  useRoutes,
  useLocation,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { CssBaseline } from "@material-ui/core";
import SnackbarMessage from "./components/SnackbarMessage";
import { setSnackbarMessage } from "./actions";
import LoginPage from "./pages/Login";
import Protected from "./pages/Protected";
import useCognitoAuth from "./hooks/useCognitoAuth";

function Auth() {
  const navigate = useNavigate();

  const location = useLocation();

  const { isLoggedIn } = useCognitoAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/protected");
      return;
    }

    if (!isLoggedIn && location.pathname !== "/login") {
      navigate("/login");
    }
  }, [isLoggedIn, location.pathname, navigate]);
  return <Outlet />;
}

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <div className="app">
        <SnackbarMessage />
        <Routes>
          <Route element={<Auth />}>
            <Route path="/protected" element={<Protected />} />
            <Route path="/" element={<Auth />} />
            {/* // <Route path="/redirecting" element={<Redirect />}></Route> */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
