import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import LoadingOverlay from "react-loading-overlay";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import { format } from "date-fns";
import config from "../../config.json";
import { getWSService } from "../../services/websocket";
import WebsocketAlert from "../WebsocketAlert";
import { useApi } from "../../api/useApi";

const usePaginationActionsStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    color: "black",
  },
}));

function TablePaginationActions(props) {
  const classes = usePaginationActionsStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon className={classes.icon} />
        ) : (
          <FirstPageIcon className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight className={classes.icon} />
        ) : (
          <KeyboardArrowLeft className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft className={classes.icon} />
        ) : (
          <KeyboardArrowRight className={classes.icon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon className={classes.icon} />
        ) : (
          <LastPageIcon className={classes.icon} />
        )}
      </IconButton>
    </div>
  );
}

function SchedulesHeader({ auth }) {
  const handleExportClick = async (e) => {
    e.preventDefault();
    alert("Coming Soon...");

    /// PLACEHOLDER FOR EXPORTING FLIGHT LOGS. DEVELOP CODES HERE FOR FLIGHT LOG EXPORT
  };

  return (
    <Grid
      container
      spacing={0}
      style={{ paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px" }}
    >
      <Grid item xs={6}>
        <Button
          variant="contained"
          style={{ fontSize: "12px", fontWeight: "bold" }}
          color="primary"
          onClick={handleExportClick}
        >
          Export Flight Logs
        </Button>
      </Grid>
    </Grid>
  );
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    height: 53,
    "&:hover": {
      background: "#b3caf3",
      cursor: "pointer",
    },
  },
  tableHeaderRow: {
    height: 53,
  },
  tableCell: {
    color: "black",
  },
  pagination: {
    color: "black",
    background: "white",
  },
}));

function OperationsTable(props) {
  const classes = useTableStyles();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const { operations } = props;

  const rowsPerPage = 10;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, operations.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const formatDate = (date) => format(new Date(date), "MM/dd/yyyy HH:mm:ss");

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="a dense table">
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            <TableCell className={classes.tableCell}> Operation </TableCell>

            <TableCell className={classes.tableCell} align="right">
              Departure
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              Arrival
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Status
            </TableCell>
            <TableCell className={classes.tableCell} align="right">
              Operation ID
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.reference.id);

              return (
                <TableRow className={classes.tableRow} key={row.reference.id}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {`${row.reference.description}`}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {formatDate(
                      row.interuss.operation_reference.time_start.value
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {formatDate(
                      row.interuss.operation_reference.time_end.value
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="center">
                    {row.details.state}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {row.reference.id}
                  </TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={5} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              count={operations.length}
              rowsPerPage={rowsPerPage}
              page={page}
              className={classes.pagination}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export function SchedulesComponent({ history, auth }) {
  const [loading, setLoading] = React.useState(true);
  const [pastOperations, setPastOperations] = React.useState([]);
  // const [platformData, setPlatformData] = React.useState([]);

  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();
  React.useEffect(() => {
    getData();
    enableSocketConnection();
  }, []);

  const enableSocketConnection = () => {
    const connection = getWSService();
    connection.addMessageListener((message) => {
      if (!message || !message.type) return;
      if (message.type === "state_change") {
        setWebsocketAlertOpen(true);
        setWebsocketMessage(message);
      }
    });
  };

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  const getData = async () => {
    const promises = [];
    promises.push(getOperations());
    // promises.push(getPlatformData())

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  // const getPlatformData = async () => {
  //   const requestParams = {
  //     method: 'post',
  //     url: `${config.mars_base_url}/platform/list`,
  //     headers: {
  //       'content-type': 'application/json',
  //     }
  //   }

  //   const response = await axios(requestParams)
  //   if(!response.data) return

  //   setPlatformData(response.data)
  // }

  const getOperations = async () => {
    const response = await api.getOperations(true);

    if (!response.data) return;

    const operations = [];

    response.data.forEach((flightData) => {
      operations.push(flightData.operation_json);
    });

    setPastOperations(operations);
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      <OperationsTable operations={pastOperations} />
      <SchedulesHeader auth={auth} />
      <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      />
    </LoadingOverlay>
  );
}
