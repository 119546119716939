import Graphic from "@arcgis/core/Graphic";

const DEFAULT_SHIP_ASPECT_RATIO = 3 / 4;
const makeSymbol = (
  { heading, height, width, depth },
  shipAspectRatio = DEFAULT_SHIP_ASPECT_RATIO
) => ({
  type: "point-3d", // autocasts as new PointSymbol3D()
  symbolLayers: [
    {
      type: "object", // autocasts as new ObjectSymbol3DLayer()
      width, // diameter of the object from east to west in meters
      height: width * shipAspectRatio, // height of object in meters
      depth, // diameter of the object from north to south in meters
      heading,
      resource: {
        primitive: "cube",
      },
      castShadows: false,
      material: {
        color: [101, 67, 33, 1],
        doubleSided: false,
      },
    },
  ],
});
// await shipLayer.addMany();
function createShipSymbols(shipData) {
  if (!shipData || !shipData.length) return [];

  const symbolMarkers = [];
  for (const ship of shipData) {
    const {
      heading,
      longitudeDegrees,
      latitudeDegrees,
      vesselBreadth,
      vesselLength,
    } = ship;
    symbolMarkers.push(
      new Graphic({
        geometry: {
          type: "point",
          x: longitudeDegrees,
          y: latitudeDegrees,
          hasZ: true,
          z: 20,
        },
        symbol: makeSymbol({
          heading,
          // Hardcoded to 50m as data does not provide
          height: 50,
          width: vesselBreadth || 100,
          depth: vesselLength || 100,
        }),
      })
    );
  }
  return symbolMarkers;
}

export default createShipSymbols;
