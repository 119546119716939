import { connect } from 'react-redux'
import { SchedulesComponent } from './Schedules'

const mapStatetoProps = state => ({
    auth: state.auth
  })

const mapDispatchToProps = dispatch => ({})

export default connect(mapStatetoProps, mapDispatchToProps)(SchedulesComponent)
