import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";


import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { blue800, amber50 } from "material-ui/styles/colors";
import { CssBaseline } from "@material-ui/core";
import sagas from "./sagas";
import reducers from "./store/reducers";
import App from "./App";

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: amber50,
  },
  tabs: {
    backgroundColor: blue800,
  },
});

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

if (process.env.NODE_ENV === "development") {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(sagas);

ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
