import Graphic from "@arcgis/core/Graphic";
import { HIGHLIGHT_COLORS } from "../../config/staticConstraintsColor";
import areasWithin5kmAerodome from "./Areas_within_5km_of_aerodromes.json";
import areasWithinDangerAreas from "./Areas_within_Danger_Areas.json";
import areasWithinProhibitedAreas from "./Areas_within_Prohibited_Areas.json";
import areasWithinRestrictedAreas from "./Areas_within_Restricted_Areas.json";
import protectedAreaUnderSection7AirNavigationAct from "./Protected_Area_under_Section_7_Air_Navigation_Act.json";

function createStaticConstraintVolume() {
  const staticConstraintsVolumeSymbols = [];

  areasWithin5kmAerodome.SrchResults.map((item) => {
    if (item?.LatLng) {
      const coordinates = [];
      const rawCoordinates = item.LatLng;
      const rawLatLngs = rawCoordinates.split("|");

      for (const latLngStr of rawLatLngs) {
        const latLng = latLngStr.split(",");
        const lat = parseFloat(latLng[0]);
        const lng = parseFloat(latLng[1]);
        coordinates.push([lng, lat]);
      }

      const polygonGraphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: [coordinates],
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size: 60, // height of the polygon
              material: {
                color: HIGHLIGHT_COLORS.orange,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      staticConstraintsVolumeSymbols.push(polygonGraphic);
    }
  });

  areasWithinDangerAreas.SrchResults.map((item) => {
    if (item?.LatLng) {
      const coordinates = [];
      const rawCoordinates = item.LatLng;
      const rawLatLngs = rawCoordinates.split("|");

      for (const latLngStr of rawLatLngs) {
        const latLng = latLngStr.split(",");
        const lat = parseFloat(latLng[0]);
        const lng = parseFloat(latLng[1]);
        coordinates.push([lng, lat]);
      }

      const polygonGraphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: [coordinates],
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size: 60, // height of the polygon
              material: {
                color: HIGHLIGHT_COLORS.red,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      staticConstraintsVolumeSymbols.push(polygonGraphic);
    }
  });

  areasWithinProhibitedAreas.SrchResults.map((item) => {
    if (item?.LatLng) {
      const coordinates = [];
      const rawCoordinates = item.LatLng;
      const rawLatLngs = rawCoordinates.split("|");

      for (const latLngStr of rawLatLngs) {
        const latLng = latLngStr.split(",");
        const lat = parseFloat(latLng[0]);
        const lng = parseFloat(latLng[1]);
        coordinates.push([lng, lat]);
      }

      const polygonGraphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: [coordinates],
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size: 60, // height of the polygon
              material: {
                color: HIGHLIGHT_COLORS.magneta,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      staticConstraintsVolumeSymbols.push(polygonGraphic);
    }
  });

  areasWithinRestrictedAreas.SrchResults.map((item) => {
    if (item?.LatLng) {
      const coordinates = [];
      const rawCoordinates = item.LatLng;
      const rawLatLngs = rawCoordinates.split("|");

      for (const latLngStr of rawLatLngs) {
        const latLng = latLngStr.split(",");
        const lat = parseFloat(latLng[0]);
        const lng = parseFloat(latLng[1]);
        coordinates.push([lng, lat]);
      }

      const polygonGraphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: [coordinates],
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size: 60, // height of the polygon
              material: {
                color: HIGHLIGHT_COLORS.darkgray,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      staticConstraintsVolumeSymbols.push(polygonGraphic);
    }
  });

  protectedAreaUnderSection7AirNavigationAct.SrchResults.map((item) => {
    if (item?.LatLng) {
      const coordinates = [];
      const rawCoordinates = item.LatLng;
      const rawLatLngs = rawCoordinates.split("|");

      for (const latLngStr of rawLatLngs) {
        const latLng = latLngStr.split(",");
        const lat = parseFloat(latLng[0]);
        const lng = parseFloat(latLng[1]);
        coordinates.push([lng, lat]);
      }

      const polygonGraphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: [coordinates],
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size: 60, // height of the polygon
              material: {
                color: HIGHLIGHT_COLORS.darkblue,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      staticConstraintsVolumeSymbols.push(polygonGraphic);
    }
  });

  return staticConstraintsVolumeSymbols;
}

export default createStaticConstraintVolume;
