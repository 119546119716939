import axios from "axios";
import config from "../config.json";

const server = {
  getFlightTelemetry: async (puckID) => {
    const requestParams = {
      method: "get",
      url: `${config.puck_base_url}/api/getownshipflights/${puckID}`,
      headers: {
        "content-type": "application/json",
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  sendCommand: async (puckID, command) => {
    const requestParams = {
      method: "post",
      url: `${config.puck_base_url}/api/sendcommand/${puckID}`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        gcs: command,
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },

  uploadCommand: async (puckID, route, speed, command) => {
    const requestParams = {
      method: "post",
      url: `${config.puck_base_url}/api/sendcommand/${puckID}`,
      data: {
        gcs: command,
        route,
        // route: [
        //     {
        //         lon: 103.86715348805345,
        //         lat: 1.2723252940785097,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87018642930259,
        //         lat: 1.2718825279408739,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87067103740121,
        //         lat: 1.2712251401402583,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.86936295270267,
        //         lat: 1.2702389140875576,
        //         alt: 30
        //     },
        //     {
        //         lon: 103.87113725130179,
        //         lat: 1.267824965911375,
        //         alt: 30
        //     }
        // ],
        speed,
      },
    };

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },
  postMission: async (OperationDetails, puckID) => {
    const requestParams = {
      method: "post",
      url: `${config.puck_base_url}/api/mission`,
      headers: {
        "content-type": "application/json",
      },
    };
    const env =
      process.env.REACT_APP_NOVAUSS_BASE_URL === "https://sp.heronairbridge.com"
        ? "prod"
        : "dev";

    if (!OperationDetails.details.waypoints) {
      requestParams.data = {
        id: Math.floor(Math.random() * 1000 + 1),
        flight_plan: {
          id: Math.floor(Math.random() * 1000 + 1),
          status: "ACCEPTED",
          node_departure: 1,
          time_departure: OperationDetails.reference.time_start.value,
          time_arrival: OperationDetails.reference.time_end.value,
          altitude_reference: "WGS84",
          rnp: 0,
          date_created: OperationDetails.reference.time_created.value,
        },
        gufi: OperationDetails.reference.id,
        operation_state: "ACCEPTED",
        rank: OperationDetails.reference.version,
        time_submitted: new Date(),
        puck_id: puckID,
        env,
      };
    } else {
      requestParams.data = {
        id: Math.floor(Math.random() * 1000 + 1),
        flight_plan: {
          id: Math.floor(Math.random() * 1000 + 1),
          status: "ACCEPTED",
          node_departure: 1,
          node_arrival: OperationDetails.details.waypoints.length,
          time_departure: OperationDetails.reference.time_start.value,
          time_arrival: OperationDetails.reference.time_end.value,
          ground_speed: OperationDetails.request.ground_speed,
          altitude_reference: "WGS84",
          route: OperationDetails.details.waypoints,
          rnp: 0,
          date_created: OperationDetails.reference.time_created.value,
        },
        gufi: OperationDetails.reference.id,
        operation_state: "ACCEPTED",
        rank: OperationDetails.reference.version,
        time_submitted: new Date(),
        puck_id: puckID,
        env,
      };
    }

    try {
      const response = await axios(requestParams);
      return [null, response.data];
    } catch (err) {
      return [err, null];
    }
  },
};

export default server;
