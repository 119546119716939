const placementPoint = (index, { lat, lon, alt }) => {
  const i = parseInt(index, 10) + 1;
  return `\t\t<Placemark id="${i}">\n\
            <name>Node ${i}</name>\n\
            <description>Altitude: ${alt} m</description>\n\
            <Point id="${i}">\n\
                <coordinates>${lon},${lat},${alt}</coordinates>\n\
            </Point>\n\
        </Placemark>\n`;
};

const placementFlightPath = (index, route) => {
  let lineString = "";
  for (const routePoint of route) {
    lineString += `${routePoint.lon},${routePoint.lat},${routePoint.alt} `;
  }

  const i = parseInt(index, 10) + 1;
  return `\t\t<Placemark id="${i}">\n\
            <name>Route</name>\n\
            <description>Flight Path</description>\n\
            <LineString id="${i}">\n\
                <coordinates>${lineString}</coordinates>\n\
                <extrude>0</extrude>\n\
                <altitudeMode>relativeToGround</altitudeMode>\n\
            </LineString>\n\
        </Placemark>\n`;
};

const kmlFooter = () => `\t</Document>
</kml>`;

const kmlHeader = () => `<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n\
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2">\n\
    <Document id="1">\n`;

function generateKML(operationData) {
  let kmlString = kmlHeader();

  // Add section for every route point in operation
  const route = operationData;
  // eslint-disable-next-line guard-for-in
  for (const i in route) {
    kmlString += placementPoint(i, route[i]);
  }
  kmlString += placementFlightPath(route.length, route);
  kmlString += kmlFooter();

  return kmlString;
}

export default generateKML;
