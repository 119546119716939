import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <button
        type="button"
        onClick={() => {
          window.location.href =
            "https://novauss.auth.ap-southeast-1.amazoncognito.com/login?client_id=51d12tn2imfhc9fpsadkbpc9fv&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://cognito-hosted-ui.novauss.com";
        }}
        style={{
          width: 277,
          height: 150,
          margin: 20,
          background: "url('/Operator.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          cursor: "pointer",
        }}
      >
        {}
      </button>
      <button
        type="button"
        onClick={() => {
          window.location.href =
            "https://airbridge-authority.auth.ap-southeast-1.amazoncognito.com/login?client_id=6em377rgjmlm4ptkqpmouuuenq&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://cognito-hosted-ui.novauss.com";
        }}
        style={{
          width: 277,
          height: 150,
          margin: 20,
          background: "url('/Authority.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          cursor: "pointer",
        }}
      >
        {}
      </button>
    </div>
  );
}

export default LoginPage;
