import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import OperationsControl from "../OperationsControl";
import { getWSService } from "../../../services/websocket";
import Weather from "../Weather";
import OverlayManager from "../../../components/OverlayManager";

const drawerWidth = 450;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

export default function TasksComponent({
  auth,
  setConstraintsData,
  selectedFlightData,
  telemetryData,
  selectedPlatformData,
  onRemoveOperation,
  onUpdateOperationState,
  isDrawerOpen = false,
  toggleShowShipLayer,
  toggleShowAdsbLayer,
  toggleShowConstraintsLayer,
  toggleShowStaticConstraintsLayer,
}) {
  const classes = useStyles();

  const [websocketMessage, setWebsocketMessage] = useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = useState(false);

  useEffect(() => {
    // getPlatformData()
    // getUserOperations()
    enableSocketConnection();
  }, []);

  useEffect(() => {
    if (!websocketMessage || !websocketMessage.type) return;

    if (websocketMessage.type === "state_change") {
      const {operationID} = websocketMessage;
      const newState = websocketMessage.new_state;

      const operations = [...selectedFlightData];
      const index = operations.findIndex((x) => x.id === operationID);
      if (index > -1) {
        operations[index].operation_state = newState;

        setWebsocketAlertOpen(true);
      }
    }
  }, [websocketMessage]);

  const enableSocketConnection = () => {
    const connection = getWSService();
    connection.addMessageListener(handleMessage);
  };

  const handleMessage = (message) => {
    setWebsocketMessage(message);
  };

  const handleRemoveOperationClick = () => {
    onRemoveOperation();
  };

  const handleVisabilityClick = (visability, operationID) => {
    //     let operations = JSON.parse(JSON.stringify(selectedFlightData));
    //     const index = operations.findIndex((x) => x.reference.id === operationID);
    //     if (index > -1) {
    //       operations[index].visable = visability;
    //       setSelectedFlightData(operations);
    //     }
  };

  const handleUpdateOperation = (newOperationData) => {
    onUpdateOperationState();
    // let operations = JSON.parse(JSON.stringify(selectedFlightData));
    // console.log(operations);
    // const index = operations.findIndex(
    //   (x) => x.reference.id === newOperationData.reference.id
    // );
    // if (index > -1) {
    //   operations[index] = newOperationData;
    //   setSelectedFlightData(operations);
    // }
  };

  return (
    <Drawer
      open={isDrawerOpen}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Paper style={{ background: "lightgrey", zIndex: 1 }}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* <Typography
            align="center"
            style={{ fontSize: "12px", fontWeight: "bold", marginTop: "0px" }}
          >
            Operations Summary
          </Typography>
          <div>
            <Typography
              align="center"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "15px",
              }}
            >
              Coming Soon...
            </Typography>
          </div> */}
          <Divider />
          <Typography
            align="center"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Operations Management
          </Typography>
          <div id="opsManagement">
            <OperationsControl
              operationsData={selectedFlightData}
              telemetryData={telemetryData}
              onRemoveClick={handleRemoveOperationClick}
              selectedPlatformData={selectedPlatformData}
              onUpdateOperationState={onUpdateOperationState}
              onVisabilityClick={handleVisabilityClick}
            />
          </div>
          <Divider />
          <Typography
            align="center"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Overlay Manager
          </Typography>
          <Paper>
            <div style={{ padding: "10px" }}>
              <OverlayManager
                toggleShowShipLayer={toggleShowShipLayer}
                toggleShowAdsbLayer={toggleShowAdsbLayer}
                toggleShowConstraintsLayer={toggleShowConstraintsLayer}
                toggleShowStaticConstraintsLayer={toggleShowStaticConstraintsLayer}
              />
            </div>
          </Paper>
          <Divider />
          <Typography
            align="center"
            style={{ fontSize: "12px", fontWeight: "bold" }}
          >
            {" "}
            Current SG Weather Information & Hourly Weather Forecast{" "}
          </Typography>
          <Weather />
        </div>
      </Paper>
    </Drawer>
  );
}
