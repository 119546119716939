import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LoadingOverlay from "react-loading-overlay";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import WebsocketAlert from "../WebsocketAlert";
import { getWSService } from "../../services/websocket";
import AddPlatformForm from "../AddPlatformForm";
import { setSnackbarMessage } from "../../actions";
import { useApi } from "../../api/useApi";

function InformationBox({
  info,
  onClick,
  index,
  onEditPlatformClick,
  onDeletePlatformClick,
}) {
  const handleClick = () => {
    onClick(index);
  };

  return (
    <Accordion onClick={handleClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          display="inline"
          variant="body2"
          style={{ marginLeft: "3px" }}
        >
          {info.platform_callsign}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0} style={{ marginBottom: "1px" }}>
          <Grid item xs={8} style={{ paddingLeft: "1px" }}>
            <DetailItem name="Tracker/Aircraft ID" value={info.puck_uuid} />
            <DetailItem
              name="Registration Number"
              value={info.platform_registration}
            />
            <DetailItem
              name="Registration Expiry"
              value={info.registration_expiry}
            />
            <DetailItem
              name="Platform Model Type"
              value={info.platform_type_uuid}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <div>
              <Button
                variant="contained"
                size="small"
                style={{ width: "130px", marginTop: "5px" }}
                onClick={onEditPlatformClick}
              >
                Edit Details
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ width: "130px", marginTop: "15px" }}
                onClick={onDeletePlatformClick}
              >
                Delete Details
              </Button>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function PlatformList(props) {
  const {
    platformList,
    onClick,
    onEditPlatformClick,
    onDeletePlatformClick,
    selected,
  } = props;
  return (
    <List>
      {platformList.map((x, index) => (
        <InformationBox
          info={x}
          index={index}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={onClick}
          onEditPlatformClick={onEditPlatformClick}
          onDeletePlatformClick={onDeletePlatformClick}
          selected={selected === index}
        />
      ))}
    </List>
  );
}

function DetailItem({ name, value }) {
  return (
    <Typography variant="body2">
      <span style={{ fontWeight: "bold", marginLeft: "1px" }}>{name} - </span>
      {value}
    </Typography>
  );
}

export default function PlatformsComponent() {
  const [selected, setSelected] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [platformList, setPlatformList] = React.useState([]);
  const [loadingPlatforms, setLoadingPlatforms] = React.useState(true);
  const [updatingPlatform, setUpdatingPlatform] = React.useState(false);
  const [platformTypes, setPlatformTypes] = React.useState([]);
  const [websocketMessage, setWebsocketMessage] = React.useState(null);
  const [websocketAlertOpen, setWebsocketAlertOpen] = React.useState(false);
  const api = useApi();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getPlatforms();
    getPlatformTypes();
    enableSocketConnection();
  }, []);

  const enableSocketConnection = () => {
    const connection = getWSService();
    connection.addMessageListener((message) => {
      if (!message || !message.type) return;
      if (message.type === "state_change") {
        setWebsocketAlertOpen(true);
        setWebsocketMessage(message);
      }
    });
  };

  const onWebsocketAlertClose = () => {
    setWebsocketAlertOpen(false);
  };

  function compare(a, b) {
    if (a.platform_callsign < b.platform_callsign) {
      return -1;
    }
    if (a.platform_callsign > b.platform_callsign) {
      return 1;
    }
    return 0;
  }
  const getPlatforms = async () => {
    try {
      const response = await api.getPlatforms();
      if (response.data) {
        response.data.sort(compare);
        setPlatformList(response.data);
        setSelected(0);
        setLoadingPlatforms(false);
      }
    } catch (err) {
      setLoadingPlatforms(false);
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const getPlatformTypes = async () => {
    try {
      const response = await api.getPlatformTypes();
      if (response.data) {
        setPlatformTypes(response.data);
      }
    } catch (err) {
      setLoadingPlatforms(false);
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  const handlePlatformClick = (item) => {
    if (selected !== item) {
      setSelected(item);
    }
  };

  const handleEditPlatformClick = () => {
    setOpenDialog(true);
    setUpdatingPlatform(true);
  };

  const handleDeletePlatformClick = () => {
    deletePlatformInfo();
  };

  const handleAddPlatformClick = () => {
    setOpenDialog(true);
    setUpdatingPlatform(false);
  };

  const handleClose = (item) => {
    setOpenDialog(false);
    setUpdatingPlatform(false);
  };

  const handlePlatformUpdate = async (newPlatformData) => {
    const res = await api.getPlatforms();
    setPlatformList(res.data);
  };

  const deletePlatformInfo = async () => {
    try {
      const response = await api.deletePlatform(
        platformList[selected].platform_uuid
      );

      if (response.data) {
        // setUserMessage("Platform Deleted!")
        window.alert("Platform deleted!");
        getPlatforms();
        handleClose();
      }
    } catch (err) {
      dispatch(
        setSnackbarMessage({
          message: err.response.data.message,
          severity: "error",
        })
      );
    }
  };

  return (
    <LoadingOverlay active={loadingPlatforms} spinner text="">
      <Grid>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{
            width: "100%",
            marginTop: "5px",
            marginLeft: "3px",
            marginRight: "3px",
          }}
          onClick={handleAddPlatformClick}
        >
          {" "}
          Add Platform{" "}
        </Button>
      </Grid>
      <PlatformList
        onClick={handlePlatformClick}
        selected={selected}
        platformList={platformList}
        onEditPlatformClick={handleEditPlatformClick}
        onDeletePlatformClick={handleDeletePlatformClick}
      />
      <AddPlatformForm
        open={openDialog}
        handleClose={handleClose}
        platformData={selected !== null ? platformList[selected] : null}
        updating={updatingPlatform}
        platformTypes={platformTypes}
        handlePlatformUpdate={handlePlatformUpdate}
      />
      <WebsocketAlert
        open={websocketAlertOpen}
        handleClose={onWebsocketAlertClose}
        websocketMessage={websocketMessage}
      />
    </LoadingOverlay>
  );
}
