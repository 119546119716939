import axios from "axios";
import config from "../config.json";

export const weatherApi = () => {
  const weather = axios.create({
    baseURL: "https://api.openweathermap.org",
  });

  const getWeatherData = ({ latitude, longitude }) => weather.request({
      method: "get",
      url: `/data/2.5/onecall`,
      params: {
        lat: latitude,
        lon: longitude,
        units: "metric",
        appid: config.weather_api_key,
      },
    });
  return {
    getWeatherData,
  };
};
