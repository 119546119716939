import axios from "axios";
import { weatherApi } from "./weather";

async function getCifToken() {
  const params = new URLSearchParams();
  params.append("grant_type", "client_credentials");
  params.append(
    "scope",
    "constraints/read constraints/write cif/constraints.read cif/constraints.write cif/constraints.delete cif/constraints.query cif/subscriptions cif/utm.constraint_management cif/utm.constraint_consumption"
  );
  params.append("client_id", "7boqj11qtcfc7gtg9j04shv2p7");
  params.append(
    "client_secret",
    "5othqs1uchdjm8ta2cbhesnu9g5fmqstjpgq8es5eatofh7k149"
  );

  const configHeaders = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  const url = `https://auth.fims.novautm.net/oauth2/token`;

  try {
    const response = await axios.post(url, params, configHeaders);
    const token = response.data.access_token;
    return token;
  } catch (err) {
    console.error(err);
    return null;
  }
}
export const createInstance = (jwtToken) => {
  /**
   * Mars API
   */
  const mars = axios.create({
    baseURL: process.env.REACT_APP_MARS_BASE_URL,
  });

  mars.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      "content-type": "application/json",
      Authorization: jwtToken,
    };
    return config;
  });

  /**
   * FIMS SDSP API
   */
  const sdsp = axios.create({
    baseURL: process.env.REACT_APP_FIMS_SDSP_URL,
  });

  // sdsp.interceptors.request.use((config) => {
  //   config.headers = {
  //     ...config.headers,
  //     "content-type": "application/json",
  //     Authorization: jwtToken,
  //   };
  //   return config;
  // });

  const getPilots = () => mars.get(`/pilot/list`);

  const createPilot = ({ name, email, phone, license, expiry }) =>
    mars.request({
      method: "post",
      url: `/pilot`,
      data: {
        pilot_name: name,
        pilot_email: email,
        pilot_contact: phone,
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const deletePilot = (pilot_uuid) =>
    mars.request({
      method: "delete",
      url: `/pilot/${pilot_uuid}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const updatePilot = (pilot_uuid, { name, email, phone, license, expiry }) =>
    mars.request({
      method: "put",
      url: `/pilot/${pilot_uuid}`,
      data: {
        pilot_name: name,
        pilot_email: email,
        pilot_contact: phone,
        pilot_registration: license,
        registration_expiry: expiry,
      },
    });

  const submitNewPlatform = ({
    callsign,
    registration,
    expiry,
    puckUUID,
    platformType,
  }) =>
    mars.request({
      method: "post",
      url: `/platform`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        puck_uuid: puckUUID,
        platform_type_uuid: platformType,
      },
    });

  const updatePlatformInfo = ({
    platform_uuid,
    callsign,
    registration,
    expiry,
    puckID,
    platformType,
  }) =>
    mars.request({
      method: "put",
      url: `/platform/${platform_uuid}`,
      data: {
        platform_callsign: callsign,
        platform_registration: registration,
        registration_expiry: expiry,
        puck_uuid: puckID,
        platform_type_uuid: platformType,
      },
    });

  const getPlatform = async (platform_uuid) =>
    mars.request({
      method: "get",
      url: `/platform/${platform_uuid}`,
    });

  const getOperationData = async ({ operationID }) =>
    mars.request({
      method: "get",
      url: `/operations/${operationID}`,
    });

  const submitNewPlatformType = ({
    model,
    airframe,
    takeoffspeed,
    cruisingspeed,
    landingspeed,
    endurance,
    radiorange,
    tsemean,
    tsevariance,
  }) =>
    mars.request({
      method: "post",
      url: `/platform/type`,
      data: {
        model,
        airframe,
        speed_takeoff: takeoffspeed,
        speed_curising: cruisingspeed,
        speed_landing: landingspeed,
        endurance,
        radio_range: radiorange,
        tse_mean: tsemean,
        tse_variance: tsevariance,
      },
    });

  const updatePlatformTypeInfo = (
    platform_type_uuid,
    {
      model,
      airframe,
      takeoffspeed,
      cruisingspeed,
      landingspeed,
      endurance,
      radiorange,
      tsemean,
      tsevariance,
    }
  ) =>
    mars.request({
      method: "put",
      url: `/platform/type/${platform_type_uuid}`,
      data: {
        model,
        airframe,
        speed_takeoff: takeoffspeed,
        speed_curising: cruisingspeed,
        speed_landing: landingspeed,
        endurance,
        radio_range: radiorange,
        tse_mean: tsemean,
        tse_variance: tsevariance,
      },
    });

  const getPlatformTypes = () =>
    mars.request({
      method: "get",
      url: `/platform/type/list`,
    });
  const submitOperation = (data) =>
    // set way points including p2p and intermediate
    mars.request({
      method: "post",
      url: `/operations/wps`,
      data,
    });
  const submitAreaOperation = (data) =>
    // set area operations
    mars.request({
      method: "post",
      url: `/operations`,
      data,
    });
  const getPlatforms = () => mars.get(`/platform/list`);

  const getOperations = (isPast) =>
    mars.get(`/operations/list${isPast ? "?isPast=true" : ""}`);

  const deleteOperation = (operationID) =>
    mars.request({
      method: "delete",
      url: `/operations/${operationID}`,
      headers: {
        "content-type": "application/json",
      },
    });

  const updateOperation = (operationID, operationState) =>
    mars.request({
      method: "post",
      url: `/operations/state`,
      headers: {
        "content-type": "application/json",
      },
      data: {
        operation_uuid: operationID,
        new_state: operationState,
      },
    });

  // /**
  //  * MarineTraffic API
  //  */
  //  const marineTrafficBaseURL = "https://services.marinetraffic.com/api/exportvessels/fc14e1966a9e9e9a9d445ba0e794c520539bfe82?v=8&timespan=5&msgtype=extended&protocol=json";

  // const getThaiShips = () => {
  //   return marineTraffic.get(marineTrafficBaseURL);
  //     // headers: {
  //     //   Authorization: null,
  //     // },
  //     // data: {
  //     //   v: 8,
  //     //   MAXLAT: 13.19086,
  //     //   MINLON: 100.77824,
  //     //   MAXLON: 100.84073,
  //     //   MINLAT: 13.10761,
  //     //   protocol: "json",
  //     //   msgtype: "simple",
  //     // },
  //   });
  // };

  const getLogMessages = async () =>
    mars.request({
      method: "GET",
      url: `/log-messages`,
    });

  const getShips = async () =>
    sdsp.request({
      method: "get",
      url: `/sdsp/ais/snapshot`,
      // params: { api_key: config.vessel_api_key },
    });

  const getConstraints = async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;
    // console.log(auth)
    return axios.request({
      method: "get",
      url: `https://fims.novautm.net/constraints/query/active/${Math.round(
        new Date().getTime() / 1000
      )}`,
      headers: {
        Authorization: auth,
      },
    });
  };

  const getAdsb = async () => {
    const token = await getCifToken();
    if (!token) throw new Error("Auth failed");
    const auth = `Bearer ${token}`;

    return axios.request({
      method: "get",
      url: `https://atm.fims.novautm.net/traffic/query/active/${Math.round(
        new Date().getTime() / 1000
      )}`,
      headers: {
        Authorization: auth,
      },
    });
  };

  const deletePlatformType = (platform_type_uuid) =>
    mars.delete(`/platform/type/${platform_type_uuid}`);

  const deletePlatform = (platform_uuid) =>
    mars.delete(`/platform/${platform_uuid}`);

  const addOperationSelection = (operation_uuid) =>
    mars.post(`/operations/selection`, { operation_uuid });

  const removeOperationSelection = (operation_uuid) =>
    mars.delete(`/operations/selection/${operation_uuid}`);

  const getAllOperationSelection = () => mars.get(`/operations/selection`);

  return {
    getPilots,
    createPilot,
    updatePilot,
    deletePilot,
    submitNewPlatform,
    updatePlatformInfo,
    submitNewPlatformType,
    updatePlatformTypeInfo,
    getPlatform,
    getOperationData,
    getShips,
    submitOperation,
    submitAreaOperation,
    getPlatforms,
    getOperations,
    deleteOperation,
    updateOperation,
    getConstraints,
    getAdsb,
    getLogMessages,
    getPlatformTypes,
    deletePlatformType,
    deletePlatform,
    addOperationSelection,
    getAllOperationSelection,
    removeOperationSelection,
    weather: weatherApi(),
    jwtToken,
  };
};
