import Graphic from "@arcgis/core/Graphic";
import {
  FLIGHT_STATES,
  HIGHLIGHT_COLORS,
} from "../../config/flightStatusColor";

function createFlightOperationVolumeSymbols(visibleFlights = []) {
  const flightVolumeSymbols = [];
  for (const visibleFlight of visibleFlights) {
    for (const vol of visibleFlight.details.operation_volumes) {
      const coords = vol.volume.outline_polygon.coordinates[0];
      coords.forEach((e) => {
        e[2] = vol.volume.altitude_lower.value;
      });

      let color = HIGHLIGHT_COLORS.gray;
      switch (visibleFlight.details.state) {
        case FLIGHT_STATES.CONTINGENT: {
          color = HIGHLIGHT_COLORS.red;
          break;
        }
        case FLIGHT_STATES.NON_CONFORMANT: {
          color = HIGHLIGHT_COLORS.orange;
          break;
        }
        case FLIGHT_STATES.ACTIVATED: {
          color = HIGHLIGHT_COLORS.green;
          break;
        }
        case FLIGHT_STATES.ENDED || FLIGHT_STATES.ACCEPTED: {
          color = HIGHLIGHT_COLORS.gray;
          break;
        }
        default:
          color = HIGHLIGHT_COLORS.gray;
      }

      const polygonGraphic = new Graphic({
        attributes: {
          id: visibleFlight.reference.id,
        },
        geometry: {
          type: "polygon",
          rings: coords,
          hasZ: true,
          z: vol.volume.altitude_upper.value,
        },
        symbol: {
          type: "polygon-3d",
          symbolLayers: [
            {
              type: "extrude",
              size:
                vol.volume.altitude_upper.value -
                vol.volume.altitude_lower.value,
              material: {
                color,
              },
              castShadows: false,
            },
          ],
          outline: {
            color: HIGHLIGHT_COLORS.gray,
            width: 1,
          },
        },
      });
      flightVolumeSymbols.push(polygonGraphic);
    }
  }
  return flightVolumeSymbols;
}

export default createFlightOperationVolumeSymbols;
