import WebSocket from "isomorphic-ws";

const WS_URL = "wss://2p8sevtd4a.execute-api.ap-southeast-1.amazonaws.com/dev";

class WebSocketService extends WebSocket {
  static WSService = null;

  static keepAlive = null;

  constructor(url) {
    super(url);
    // eslint-disable-next-line no-constructor-return
    if (WebSocketService.WSService) return WebSocketService.WSService;
    this.messageListeners = [];
    this.isOpen = false;
    WebSocketService.WSService = this;
  }

  /**
   *  Show connection status to us in the log
   */
  onopen = () => {
    console.info("ws connection open");
    this.isOpen = true;
    // API gateway times out after 10 min of inactivity. Send ping every 9 min to keep connection alive.
    WebSocketService.keepAlive = setInterval(() => {
      this.sendMessage("ping", { message: "test" });
    }, 540000);
  };

  /**
   *  Log lost connection for now
   */
  // eslint-disable-next-line class-methods-use-this
  onclose = () => {
    clearInterval(WebSocketService.keepAlive);
  };

  /**
   *  Used by application to send message to the WebSocket API Gateway
   *  @param routeKey The route key for WebSocket API Gateway
   *  @param message String message
   */
  sendMessage = (routeKey, message) => {
    if (WebSocketService.WSService && this.isOpen) {
      WebSocketService.WSService.send(
        JSON.stringify({
          message: routeKey,
          data: JSON.stringify(message),
        })
      );
    } else {
      console.error(message);
    }
  };

  /**
   *  Used by application to register different listeners for
   *  different message types
   *  @param listener Function to handle message type
   */
  addMessageListener = (listener) => {
    if (typeof listener !== "function") {
      return;
    }
    this.messageListeners = listener;
  };

  /**
   * Handler that receives the actual messages from the WebSocket API
   * For now it simply returns the parsed message body to the appropriate
   * registered handler
   * @param data Message body received from WebSocket
   */
  onmessage = (data) => {
    console.info("ws connection data", data.data);
    if (data) {
      const message = JSON.parse(data.data);
      this.messageListeners(message);
    }
  };
}
export const wsSingleton = new WebSocketService(WS_URL);
export const getWSService = () => wsSingleton;
